import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HelpersService } from '../../../services/helpers.service';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
    @Input() heads: { name: string; code: string; sortable?: boolean }[];
    @Input() data: any;
    @Input() sort?: { code: string; dir: 'asc' | 'desc' };
    @Input() emptyState?: string;
    @Input() RPP?: number = 20;
    @Input() startRow?: number = 0;
    @Input() totalRows?: number = null;
    @Input() loading: boolean = true;
    @Input() ready: boolean = true;
    @Input() timeout: number = 0;
    @Input() notReady: boolean = false;
    @Input() exportLabel: string = '';
    @Input() exportBusy: boolean = false;
    @Input() canAddRows: boolean = false;
    @Input() selected: string = '';
    @Input() addRowFormSTR: any = {};
    @Input() classes: string = '';
    @Input() linkElement: boolean = false;
    @Input() id: any = 'id';
    @Output() sortChange = new EventEmitter();
    @Output() clickData = new EventEmitter();
    @Output() clickAction = new EventEmitter();
    @Output() startRowChange = new EventEmitter();
    @Output() clickExport = new EventEmitter();
    // @Output() addRowToTable = new EventEmitter();

    clickable = false;
    helper: any = {};
    shimmerRows = [];
    popoverHelper: any = null;
    loadingTimedOut: boolean = false;
    loadingTimeout: any = null;

    constructor(public HelpersService: HelpersService) { }

    ngOnInit(): void {
        this.clickable = this.clickData.observers.length > 0;
        this.shimmerRows = Array(20 || this.RPP);
        if (this.timeout) {
            this.notReady = true;
            setTimeout(() => {
                this.notReady = false;
            }, this.timeout);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.loading) {
            if (changes.loading.currentValue === true) {
                this.loadingTimeout = setTimeout(() => {
                    this.loadingTimedOut = true;
                }, 800);
            } else {
                clearTimeout(this.loadingTimeout);
                this.loadingTimedOut = false;
            }
        }
    }

    identify(index, item) {
        return index;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    getSortingClass(head: any) {
        if (head.sortable && this.sort.code === head.code) {
            return `sort-${this.sort.dir}`;
        } else if (head.sortable) {
            return 'sort-none';
        }
    }

    clickSort(head: any) {
        let result = this.sort;
        if (this.sort.code === head.code) {
            result.dir = this.sort.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: head.code, dir: 'asc' };
        }
        this.sortChange.emit(result);
    }
}
