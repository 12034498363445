import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { title } from 'process';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { ResourceService } from 'src/app/services/resource.service';
import { SearchService } from 'src/app/services/search.service';
import { DefaultService } from 'src/app/utils/api';
import { eventColorPipe, TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
    items: any[] = null;
    SEARCH: any = {};
    SEARCHED: any[] = [];
    SORT: any = {};
    RPP: number = 40;
    startRow: number = 0;
    totalRows: number = undefined;
    previousParams: any = null;
    typeaheadModels: any = {
        project: null
    };
    user: any = null;
    options: any = {};
    helper: any = {};
    subscriptions: any = {};
    internal: boolean = false;

    // table
    heads: any;
    data: any;

    ready: boolean = false;
    loading: boolean = false;

    selectedItemId: any = null;
    selectedItemLoading: boolean = true;
    selectedItem: any = null;

    advancedSearchOpen: boolean = false;

    constructor(
        public Route: ActivatedRoute,
        private Router: Router,
        public HelpersService: HelpersService,
        public SearchService: SearchService,
        private TranslatePipe: TranslatePipe,
        private EventColorPipe: eventColorPipe,
        private AuthenticationService: AuthenticationService,
        public ResourceService: ResourceService,
        private DefaultService: DefaultService
    ) {
        this.Route.queryParams.subscribe((params: any) => {
            const parameters = Object.assign({}, params);
            if (this.ready) {
                if (!this.HelpersService.getParam('id')) this.closeDetail();
                if (this.HelpersService.getParam('id') && this.HelpersService.getParam('id') !== this.selectedItemId)
                    this.openDetail(this.HelpersService.getParam('id'));
            }
            if (parameters.id) delete parameters['id'];
            if (parameters.updateoverview) {
                this.getItems();
                this.Router.navigate([], {
                    queryParams: {
                        updateoverview: null
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: true
                });
                return;
            }
            if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
                return;
            }
            this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
            this.SEARCH = parameters.search
                ? this.getInitialSearch(JSON.parse(parameters.search))
                : this.getInitialSearch();
            this.SORT = parameters.sort ? JSON.parse(parameters.sort) : this.getInitialSort();
            let code = undefined;
            if (parameters.page !== this.previousParams?.page || this.previousParams?.page !== undefined)
                code = 'changeStartRow';
            this.getItems(code);
            this.previousParams = parameters;
        });
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
            if (
                next.roles.find((item) => {
                    return item === 'internal';
                })
            ) {
                this.internal = true;
            } else this.internal = false;
        });
    }

    ngOnInit(): void {
        this.initHeads();
        this.options = {
            environment: [
                ...this.ResourceService.getResource('environment'),
                { title: this.TranslatePipe.transform('misc_all'), value: 'all' }
            ],
            event_type: [...this.ResourceService.getResource('event_type')]
        };
    }

    // START SEARCH

    getInitialSearch(SEARCH?: any) {
        if (SEARCH) {
            return {
                ...this.getDefaultSearch(),
                ...SEARCH
            };
        } else return this.getDefaultSearch();
    }

    getDefaultSearch() {
        return {
            environment: this.ResourceService.getResourceByCode('environment', 'production').id
        };
    }

    getInitialSort() {
        return {
            code: 'event_ts',
            dir: 'desc'
        };
    }

    clickSort(code: string, dir?: string) {
        this.Router.navigate([], {
            queryParams: {
                sort: JSON.stringify(
                    this.SearchService.getCleanSort(
                        this.SearchService.getSort(code, this.SORT, dir),
                        this.getInitialSort()
                    )
                )
            },
            queryParamsHandling: 'merge'
        });
    }

    search = (reset?: boolean, SEARCH?: any, code?: string) => {
        this.advancedSearchOpen = false;
        if (code === 'enter') {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(SEARCH || this.SEARCH)),
                    page: null,
                    updateoverview: 'yes'
                },
                queryParamsHandling: 'merge'
            });
        } else if (reset) {
            this.Router.navigate([], {
                queryParams: {
                    search: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(SEARCH || this.SEARCH)),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    };

    advancedSearch(SEARCH) {
        this.SEARCH = { ...this.SEARCH, ...SEARCH, term: '' };
        this.search();
    }

    resetStartRow() {
        this.Router.navigate([], {
            queryParams: {
                page: null
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(newStartRow: number) {
        this.Router.navigate([], {
            queryParams: {
                page: this.SearchService.getCleanPage(newStartRow, this.RPP)
            },
            queryParamsHandling: 'merge'
        });
    }

    // END SEARCH

    // START CUSTOM

    initHeads() {
        const heads: any[] = [
            {
                name: this.TranslatePipe.transform('events_th_to'),
                code: 'to',
                sortable: true,
                width: '21%'
            },
            {
                name: this.TranslatePipe.transform('events_th_subject'),
                code: 'subject',
                sortable: true,
                width: '27%'
            },
            {
                name: this.TranslatePipe.transform('events_th_event'),
                code: 'event',
                sortable: true,
                width: '12%'
            },
            {
                name: this.TranslatePipe.transform('mails_th_environment'),
                code: 'environment',
                sortable: false,
                width: '10%'
            },
            {
                name: this.TranslatePipe.transform('events_th_event_ts'),
                code: 'event_ts',
                sortable: true,
                width: '15%'
            },
            {
                name: this.TranslatePipe.transform('events_th_sent_ts'),
                code: 'sent_ts',
                sortable: true,
                width: '15%'
            }
        ];
        if (this.internal) {
            heads.push({
                name: '',
                code: 'actions',
                width: ''
            });
        }
        this.heads = heads;
    }

    openDetail(id, refresh?) {
        if (this.selectedItemId == id && !refresh) {
            this.closeDetail();
        } else {
            this.selectedItemId = id;
            this.selectedItemLoading = true;
            this.Router.navigate([], {
                queryParams: {
                    id: id
                },
                queryParamsHandling: 'merge'
            });
            this.DefaultService.mailsGetMails(
                ['sent_ts desc'],
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                id
            ).subscribe(
                (next) => {
                    this.selectedItem = next;
                    this.selectedItemLoading = false;
                },
                (error) => {
                    this.selectedItemLoading = false;
                }
            );
        }
    }

    closeDetail() {
        this.selectedItemId = null;
        this.Router.navigate([], {
            queryParams: {
                id: null
            },
            queryParamsHandling: 'merge'
        });
    }

    tableClick(item: any) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.openDetail(item.id);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'copy_raw_message':
                this.HelpersService.copyToClipboard(
                    JSON.stringify(item.raw),
                    this.TranslatePipe.transform('events_raw_copied_msg')
                );
                break;
        }
    }

    getItems = (code?: string) => {
        this.SearchService.formatTags({ ...this.SEARCH }, this.getDefaultSearch()).then(
            (response) => {
                this.SEARCHED = response;
                this.loading = true;
                const SORT = { ...this.SORT };
                switch (SORT.code) {
                    case 'to':
                        SORT.code = 'email';
                        break;
                    case 'event':
                        SORT.code = 'event_type';
                        break;
                }
                const SEARCH = this.SearchService.getSearchBody(this.SEARCH, SORT, this.RPP, this.startRow);
                if (SEARCH?.environment === 'all') delete SEARCH.environment;
                this.subscriptions?.getItems?.unsubscribe();
                this.subscriptions.getItems = this.DefaultService.eventsGetEvents(
                    SEARCH.orderBy,
                    SEARCH.startRow,
                    SEARCH.RPP,
                    SEARCH.term,
                    SEARCH.to,
                    SEARCH.sent_on_from,
                    SEARCH.sent_on_until,
                    SEARCH.event_on_from,
                    SEARCH.event_on_until,
                    SEARCH.event,
                    SEARCH.environment,
                    SEARCH.projectId
                ).subscribe(
                    (next: any) => {
                        if (this.startRow && this.startRow >= next.rows) {
                            this.resetStartRow();
                            return;
                        }
                        this.items = this.getFormattedItems(next.data);
                        if (!this.ready && this.HelpersService.getParam('id')) {
                            this.openDetail(this.HelpersService.getParam('id'));
                        }
                        this.totalRows = next.rows;
                        this.ready = true;
                        this.loading = false;
                        if (code === 'changeStartRow') this.HelpersService.toTop();
                    },
                    (error) => {
                        // this.clickSort(this.getInitialSort().code, this.getInitialSearch().dir);
                        // setTimeout(() => {
                        //     this.search(true);
                        // }, 1);
                    }
                );
            },
            (error) => {
                // this.clickSort(this.getInitialSort().code, this.getInitialSearch().dir);
                // setTimeout(() => {
                //     this.search(true);
                // }, 1);
            }
        );
    };

    getFormattedItems(items) {
        return items.map((item) => {
            return this.getFormattedItem(item);
        });
    }

    getFormattedItem(item) {
        return {
            ...item,
            to: {
                type: 'title',
                title: item.email,
                mw0: true
            },
            subject: {
                type: 'default',
                value: item.subject,
                ellipsis: true,
                mw0: true
            },
            event: {
                type: 'default',
                value: item.event_type,
                color: this.EventColorPipe.transform(item.event_type_code)
            },
            environment: {
                type: 'default',
                value: item.environment
            },
            event_ts: {
                type: 'ts',
                ts: item.event_ts,
                format: 'prettyDateTime'
            },
            sent_ts: {
                type: 'ts',
                ts: item.sent_ts,
                format: 'prettyDateTime'
            },
            actions: {
                type: 'actions',
                actions: [
                    {
                        name: this.TranslatePipe.transform('events_action_copy_raw_message'),
                        code: 'copy_raw_message',
                        icon: 'clipboard'
                    }
                ]
            }
        };
    }

    resent() {
        this.getItems();
        if (this.selectedItemId) this.openDetail(this.selectedItemId, true);
        setTimeout(() => {
            this.HelpersService.setBusy(false);
        }, 400);
    }

    clearTypeaheads() {
        this.typeaheadModels = {
            project: null
        };
    }

    handleTypeaheads() {
        this.clearTypeaheads();
        if (this.SEARCH['project']) {
            this.typeaheadModels['project'] = {
                title:
                    this.AuthenticationService.getUserValue()['projects'].find((item) => {
                        return item.value === this.SEARCH['project'];
                    }).title || this.SEARCH['project'],
                value: this.SEARCH['project']
            };
        }
    }
    // END CUSTOM
}
