<div class="slideout-wrap" [class.open]="selectedItemId" [class.resizing]="status === 1" [style.width.px]="width" #box (window:mouseup)="setStatus($event, 0)">
    <div class="resize" (mousedown)="setStatus($event, 1)"></div>
    <div class="slideout-wrap-inner">
        <button class="button-collapse" (click)="emitClose.emit()"><i class="im-icon" [class.im-icon-chev-right]="true"></i></button>
        <!-- loading -->
        <ng-container *ngIf="(selectedItemLoading || !selectedItem) && selectedItem !== false">
            <app-spinner [centerVertically]="true"></app-spinner>
        </ng-container>
        <!-- body -->
        <ng-container *ngIf="!selectedItemLoading && selectedItem || selectedItem === false">
            <ng-content></ng-content>
        </ng-container>
    </div>
</div>