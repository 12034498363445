import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

const enum Status {
    OFF = 0,
    RESIZE = 1,
    MOVE = 2
}

@Component({
    selector: 'app-slideout-wrap',
    templateUrl: './slideout-wrap.component.html',
    styleUrls: ['./slideout-wrap.component.scss']
})
export class SlideoutWrapComponent implements OnInit {
    @Input() selectedItemId: string = null;
    @Input() selectedItemLoading: boolean = false;
    @Input() selectedItem: any = null;

    @Output() emitClose = new EventEmitter();

    width: number = 800;
    public mouse: { x: number; y: number };
    private boxPosition: { left: number; top: number };
    public status: Status = Status.OFF;

    @ViewChild('box') public box: ElementRef;
    @HostListener('window:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        this.mouse = { x: event.clientX, y: event.clientY };

        if (this.status === Status.RESIZE) this.resize();
    }

    constructor() { }

    ngOnInit(): void {
        if (localStorage.getItem('slideoutWidth')) {
            this.width = parseInt(localStorage.getItem('slideoutWidth'));
        }
    }

    private resize() {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const width = Math.abs(this.mouse.x - vw);
        this.width = width;
        localStorage.setItem('slideoutWidth', width.toString());
    }

    private loadBox() {
        const { left, top } = this.box.nativeElement.getBoundingClientRect();
        this.boxPosition = { left, top };
    }

    setStatus(event: MouseEvent, status: number) {
        this.loadBox();
        if (status === 1) {
            event.stopPropagation();
        } else this.loadBox();
        this.status = status;
    }
}
