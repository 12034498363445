import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlocklistComponent } from './pages/blocklist/blocklist.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { KeysComponent } from './pages/keys/keys.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EventsComponent } from './pages/events/events.component';
import { MailsComponent } from './pages/mails/mails.component';
import { LeaveGuardService } from './services/leave-guard.service';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canDeactivate: [LeaveGuardService],
        data: { title: 'dashboard' }
    },
    {
        path: 'mails',
        component: MailsComponent,
        canDeactivate: [LeaveGuardService],
        data: { title: 'mails' }
    },
    {
        path: 'events',
        component: EventsComponent,
        canDeactivate: [LeaveGuardService],
        data: { title: 'events' }
    },
    {
        path: 'blocklist',
        component: BlocklistComponent,
        canDeactivate: [LeaveGuardService],
        data: { title: 'blocklist' }
    },
    {
        path: 'projects',
        component: ProjectsComponent,
        canDeactivate: [LeaveGuardService],
        data: { title: 'projects' }
    },
    {
        path: 'clients',
        component: ClientsComponent,
        canDeactivate: [LeaveGuardService],
        data: { title: 'clients' }
    },
    {
        path: 'keys',
        component: KeysComponent,
        canDeactivate: [LeaveGuardService],
        data: { title: 'keys' }
    },
    // 404
    { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', anchorScrolling: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
