import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    AfterViewInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import * as moment from 'moment';
@Component({
    selector: 'app-field-date',
    templateUrl: './field-date.component.html',
    styleUrls: ['./field-date.component.scss']
})
export class FieldDateComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('dp') mydp: AngularMyDatePickerDirective;
    @Input() positionDateSelector: string = 'top';
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() model: string;
    @Input() focus: boolean = false;
    @Input() width: string = null;
    @Output() modelChange = new EventEmitter();
    @Output() changed = new EventEmitter();
    inputValue: string = '';
    calendarIsOpen: boolean = false;
    isFocused: boolean = false;

    localModel: IMyDateModel = null;
    locale: string = 'en';

    datepickerOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'dd-mm-yyyy',
        sunHighlight: false,
        markCurrentDay: true,
        selectorHeight: '272px',
        selectorWidth: '244px'
    };

    constructor() {}

    @ViewChild('myinput') myInputField: ElementRef;
    ngAfterViewInit() {
        if (this.focus) {
            this.myInputField.nativeElement.focus();
        }
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (!this.model) {
            this.localModel = null;
            return;
        }
        const date = moment(this.model);
        if (!date.isValid()) return;
        const day = date.date();
        const month = date.month() + 1;
        const year = date.year();
        if (changes.model) {
            this.localModel = {
                isRange: false,
                singleDate: {
                    date: {
                        year: year,
                        month: month,
                        day: day
                    }
                }
            };
        }
    }

    changes() {
        this.changed.emit();
    }

    onDateChanged(event: IMyDateModel) {
        if (moment(event.singleDate.jsDate).isValid()) {
            this.modelChange.emit(moment(event.singleDate.jsDate).format('YYYY-MM-DD'));
        } else this.modelChange.emit(null);
    }

    invalid() {
        setTimeout(() => {
            this.modelChange.emit(null);
        }, 1);
        // this.mydp.clearDate();
    }

    onBlur($event?) {
        this.isFocused = false;
        if (!this.inputValue) {
            return;
        }
        let value = this.inputValue;
        if (this.inputValue.length == 8) {
            const dayString = this.inputValue.slice(0, 2);
            const monthString = this.inputValue.slice(2, 4);
            const yearString = this.inputValue.slice(4, 8);
            value = `${dayString}-${monthString}-${yearString}`;
        }
        value = value.replace(/\s+/g, '-');
        value = value.replace(/\./g, '-');
        value = value.replace(/\//g, '-');

        if (value.split('-', 3).length != 3) {
            this.invalid();
            return;
        }
        const dayString = value.split('-', 3)[0];
        const monthString = value.split('-', 3)[1];
        const yearString = value.split('-', 3)[2];
        if (dayString.length != 2 || monthString.length != 2 || yearString.length != 4) {
            this.invalid();
            return;
        }
        const date = moment(`${yearString}-${monthString}-${dayString}`);
        if (!date.isValid()) {
            this.invalid();
            return;
        }
        const day = date.date();
        const month = date.month() + 1;
        const year = date.year();
        this.localModel = {
            isRange: false,
            singleDate: {
                date: {
                    year: year,
                    month: month,
                    day: day
                }
            }
        };

        this.modelChange.emit(date.format('YYYY-MM-DD'));
    }

    invalidFieldStyle() {
        return this.inputValue && !this.localModel && !this.isFocused && !this.calendarIsOpen;
    }

    calendarToggle($event) {
        if ($event == 1) {
            this.calendarIsOpen = true;
        } else this.calendarIsOpen = false;
    }

    onFocus() {
        this.isFocused = true;
    }

    onInputChange($event) {
        this.inputValue = $event.value;
    }
}
