import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HelpersService } from 'src/app/services/helpers.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import * as postalMime from 'postal-mime';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from 'src/app/utils/api';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-mail-slideout',
    templateUrl: './mail-slideout.component.html',
    styleUrls: ['./mail-slideout.component.scss']
})
export class MailSlideoutComponent implements OnInit, OnChanges {
    @Input() mail: any = {};
    @Input() error: boolean = false;
    @Input() open: boolean = false;
    @Input() ready: boolean = false;
    @Output() dismiss = new EventEmitter();
    @Output() resent = new EventEmitter();

    helper: any = {};
    recipientLimit: any = 2;
    mailReady: boolean = false;
    mailObject: any = null;

    constructor(
        private HelpersService: HelpersService,
        private TranslatePipe: TranslatePipe,
        private http: HttpClient,
        private DefaultService: DefaultService,
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {
        let that = this;
        document.onkeydown = function (evt: any) {
            if (!that.open) return;
            evt = evt || window.event;
            var isEscape = false;
            if ('key' in evt) {
                isEscape = evt.key === 'Escape' || evt.key === 'Esc';
            } else {
                isEscape = evt.keyCode === 27;
            }
            if (isEscape) {
                that.dismiss.emit();
            }
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.mail) {
            this.mailReady = false;
            this.mailObject = null;
            this.error = false;
            this.open = false;
            this.ready = false;
            this.helper = {};
            if (changes.mail.currentValue) {
                if (this.mail.blob_url) {
                    this.getMail();
                } else {
                    this.mailReady = true;
                }
            } else this.error = true;
        }
    }

    async getMail() {
        const PostalMime = postalMime.default;
        this.http.get(this.mail.blob_url, { responseType: 'arraybuffer' }).subscribe((next: any) => {
            this.HelpersService.setBusy(true);
            setTimeout(() => {
                new PostalMime()
                    .parse(next)
                    .then((res) => {
                        if (res.attachments) {
                            for (let i = 0; i < res.attachments.length; i++) {
                                const attachment: any = res.attachments[i];
                                const blob = new Blob([attachment.content], { type: attachment.mimeType });
                                attachment.blob = blob;
                            }
                        }
                        this.HelpersService.setBusy(false);
                        if (res?.html) {
                            const pattern = /<script(\s+(\w+\s*=\s*("|').*?\3)\s*)*\s*(\/>|>.*?<\/script\s*>)/g;
                            res.html = res.html.replaceAll(pattern, '');
                            res.html = res.html.replace(
                                /<img([^>]*)\ssrc=(['"])((?:[^\2\/>]*\/)*([^\2>]+))\2/gim,
                                '<img$1 src=$2/api/file-proxy?uri=$3$2'
                            );
                        }
                        this.mailObject = res;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.HelpersService.setBusy(false);
                    })
                    .finally(() => {
                        this.mailReady = true;
                    });
            }, 100);
        });
    }

    resizeIframe(iframe) {
        // frame height
        const frame: any = document.getElementById('mailFrame');
        if (frame) {
            frame.height = '';
            frame.height = frame.contentWindow.document.body.scrollHeight + 'px';
        }
        // frame style
        const style = document.createElement('style');
        style.textContent = `
                body {
                    margin: 0;padding: 0;
                    font-family: sans-serif;
                    
                };
            `;
        frame.contentDocument.head.appendChild(style);
        const base = document.createElement('base');
        base.target = '_blank';
        frame.contentDocument.head.appendChild(base);
    }

    copyRaw(event) {
        this.HelpersService.copyToClipboard(
            JSON.stringify(event.raw),
            this.TranslatePipe.transform('events_raw_copied_msg')
        );
    }

    resend(email?: string) {
        this.HelpersService.setBusy(true);
        this.DefaultService.mailsResendMail(this.mail.id, email).subscribe(
            (next) => {
                this.toastr.success(
                    this.TranslatePipe.transform('toast_resent_msg'),
                    this.TranslatePipe.transform('toast_resent_title')
                );
                this.resent.emit();
            },
            (error) => {
                this.HelpersService.setBusy(false);
            },
            () => {
                this.HelpersService.setBusy(false);
            }
        );
    }
}
