<div class="page">
    <!-- top bar -->
    <section class="top-bar" [class.no-pointer]="loading && false">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap">
                    <!-- search bar -->
                    <app-field-text
                        [model]="SEARCH.term" 
                        (modelChange)="SEARCH.term = $event" 
                        (enter)="search(undefined, undefined, 'enter')"
                        [placeholder]="'blocklist_search_placeholder' | translate">
                    </app-field-text>
                    <!-- advanced search -->
                    <div class="advanced-search-wrap advanced-search-wrap--compact" *ngIf="advancedSearchOpen">
                        <!-- advanced search head -->
                        <div class="title">
                            <span>{{'misc_advanced_search' | translate}}</span>
                            <span class="x" (click)="advancedSearchOpen = false"><i class="im-icon im-icon-x-light"></i></span>
                        </div>
                        <!-- advanced search body -->
                        <div class="row display-row">
                            <div class="col-12">
                                <app-field-text
                                    [model]="SEARCH.term" 
                                    (modelChange)="SEARCH.term = $event" 
                                    (enter)="search(undefined, undefined, 'enter')"
                                    [label]="'blocklist_search_term' | translate"
                                    [placeholder]="'blocklist_search_term_placeholder' | translate">
                                </app-field-text>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-12">
                                <app-field-text
                                    [model]="SEARCH.message" 
                                    (modelChange)="SEARCH.message = $event" 
                                    (enter)="search(undefined, undefined, 'enter')"
                                    [label]="'blocklist_search_message' | translate"
                                    [placeholder]="'blocklist_search_message_placeholder' | translate">
                                </app-field-text>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-6">
                                <app-field-date 
                                    [model]="SEARCH.bounce_on_from"
                                    (modelChange)="SEARCH.bounce_on_from = $event;"
                                    [label]="'blocklist_search_bounce_on_from' | translate"
                                    [placeholder]="'misc_date_placeholder' | translate"
                                    width="100%"
                                    positionDateSelector="bottom"
                                ></app-field-date>
                            </div>
                            <div class="col-6">
                                <app-field-date 
                                    [model]="SEARCH.bounce_on_until"
                                    (modelChange)="SEARCH.bounce_on_until = $event;"
                                    [label]="'blocklist_search_bounce_on_until' | translate"
                                    [placeholder]="'misc_date_placeholder' | translate"
                                    width="100%"
                                    positionDateSelector="bottom"
                                ></app-field-date>
                            </div>
                        </div>
                        <!-- advanced search foot -->
                        <div class="sep"></div>
                        <div class="foot">
                            <button (click)="search(true);" class="button-link underline"><span>{{'misc_reset_search' | translate}}</span></button>
                            <button (click)="search();" class="button default dark text-center d-block">{{'misc_search_btn' | translate}}</button>
                        </div>
                    </div>
                    <div class="advanced-search-backdrop" *ngIf="advancedSearchOpen" (click)="advancedSearchOpen = false"></div>
                </div>
                <!-- advanced search button -->
                <button (click)="advancedSearchOpen = true; handleTypeaheads();" class="button-link underline pt-xxs pb-xxs">{{'misc_advanced_search' | translate}}</button>
            </div>
            <div class="search-sub-wrap d-flex">
                <div class="d-flex align-items-start">
                    <div class="tags" *ngIf="SEARCHED?.length">
                        <div class="tag d-inline-flex align-items-center" *ngFor="let tag of SEARCHED">
                            <span><span class="title">{{tag.title}}: </span><span class="value">{{tag.value}}</span></span>
                            <i class="im-icon im-icon-close-round-filled" (click)="SearchService.removeTag(tag.key, SEARCH, search)"></i>
                        </div>
                    </div>
                    <!-- reset search button -->
                    <button *ngIf="SEARCHED?.length" (click)="search(true)" class="button-link underline">{{'misc_reset_search' | translate}}</button>
                </div>
            </div>
        </div>
        <!-- profile right -->
        <div class="profile-wrap d-flex align-items-center">
            <app-profile-section></app-profile-section>
        </div>
    </section>
    <!-- end top bar -->

    <!-- title -->
    <h1 class="">{{'blocklist_title' | translate}} <span [class.saving]="loading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h1>

    <!-- table -->
    <div class="table-wrap">
        <app-table
            [heads]="heads" 
            [data]="items"
            [sort]="SORT"
            [RPP]="RPP"
            [startRow]="startRow"
            [totalRows]="totalRows"
            [loading]="loading"
            [ready]="ready"
            [timeout]="0"
            [selected]=""
            [emptyState]="'blocklist_emptystate' | translate"
            (sortChange)="clickSort($event.code, $event.dir)"
            (clickAction)="actionClick($event.item, $event.action)"
            (startRowChange)="changeStartRow($event)"
        ></app-table>
    </div>
</div>