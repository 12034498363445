import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    saving: any = {};
    internal: boolean = false;
    dateRange$ = new BehaviorSubject<any>({});
    dateRange: string = '';
    allowNextMonth: boolean = false;
    ready: boolean = false;
    loading: boolean = false;
    clientStats: any[] = null;
    projectStats: any[] = null;
    statsToday: any = {};

    constructor(private AuthenticationService: AuthenticationService, private DefaultService: DefaultService) { }

    ngOnInit(): void {
        if (
            this.AuthenticationService.getUserValue()?.roles.find((item) => {
                return item === 'internal';
            })
        ) {
            this.internal = true;
        } else this.internal = false;
        this.dateRange$.subscribe((next) => {
            if (next?.start_date && next?.end_date) {
                this.dateRange = `${moment(next.start_date).format('D MMM, YYYY')} — ${moment(next.end_date).format(
                    'D MMM, YYYY'
                )}`;
                if (moment(next?.start_date).isSameOrAfter(moment().startOf('month'))) {
                    this.allowNextMonth = false;
                } else this.allowNextMonth = true;
                this.getStats();
            }
        });
        this.getStatsToday();
    }

    getStats() {
        const getProjectStats = () => {
            this.DefaultService.statsGetStatsByProject(month, year).subscribe((next) => {
                this.projectStats = next;
                this.loading = false;
                if (!this.ready) {
                    setTimeout(() => {
                        this.ready = true;
                    }, 100);
                }
            });
        };
        const month = parseInt(moment(this.dateRange$.getValue().start_date).format('MM'));
        const year = parseInt(moment(this.dateRange$.getValue().start_date).format('YYYY'));

        this.loading = true;
        if (this.internal) {
            this.DefaultService.statsGetStatsByClient(month, year).subscribe((next) => {
                this.clientStats = next;
                getProjectStats();
            });
        } else {
            getProjectStats();
        }
    }

    getStatsToday() {
        this.DefaultService.statsGetStatsByToday().subscribe((next) => {
            setTimeout(() => {
                this.statsToday = next[0];

                this.dateRange$.next({
                    start_date: moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: moment().endOf('month').format('YYYY-MM-DD')
                });
            }, 100);
        });
    }

    export(code: string) {
        const month = parseInt(moment(this.dateRange$.getValue().start_date).format('MM'));
        const year = parseInt(moment(this.dateRange$.getValue().start_date).format('YYYY'));
        switch (code) {
            case 'projects':
                this.saving.projects = true;
                window.location.href = `/api/stats/export/projects?month=${month}&year=${year}`;
                setTimeout(() => {
                    this.saving.projects = false;
                }, 400);
                break;
            case 'clients':
                this.saving.clients = true;
                window.location.href = `/api/stats/export/clients?month=${month}&year=${year}`;
                setTimeout(() => {
                    this.saving.clients = false;
                }, 400);
                break;
        }
    }

    prevMonth() {
        const month = moment(this.dateRange$.getValue().start_date).subtract(1, 'M');
        this.dateRange$.next({
            start_date: month.startOf('month').format('YYYY-MM-DD'),
            end_date: month.endOf('month').format('YYYY-MM-DD')
        });
    }

    nextMonth() {
        const month = moment(this.dateRange$.getValue().start_date).add(1, 'M');
        this.dateRange$.next({
            start_date: month.startOf('month').format('YYYY-MM-DD'),
            end_date: month.endOf('month').format('YYYY-MM-DD')
        });
    }
}
