import { Injectable } from '@angular/core';
import { TranslatePipe } from '../utils/pipes';
import { HelpersService } from './helpers.service';
import { ResourceService } from './resource.service';
import * as moment from 'moment';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    searchNames: any = {};

    constructor(
        public TranslatePipe: TranslatePipe,
        public HelpersService: HelpersService,
        public ResourceService: ResourceService,
        private AuthenticationService: AuthenticationService
    ) { }

    storeSearchName($event, code) {
        this.searchNames[$event.item.value] = $event.item.title;
    }

    getSearchNameById(id) {
        return this.searchNames[id];
    }

    async formatTags(SEARCHED: any, initialSEARCH: any) {
        const result = [];
        let searchNames;
        // const fetchAsyncKeys = ['pharmaceuticalForm', 'routeOfAdministration', 'atcCode'];
        const fetchAsyncKeys = [];
        for (var i = fetchAsyncKeys.length - 1; i >= 0; i--) {
            const item = fetchAsyncKeys[i];
            if (!SEARCHED[item] || this.searchNames[SEARCHED[item]]) {
                fetchAsyncKeys.splice(i, 1);
            }
            if (SEARCHED[item] && this.searchNames[SEARCHED[item]]) {
                result.push({
                    // title: this.TranslatePipe.transform(`tagname_${item}`) + `: ${this.searchNames[SEARCHED[item]]}`,
                    title: this.TranslatePipe.transform(`tagname_${item}`),
                    value: this.searchNames[SEARCHED[item]],
                    key: item
                });
            }
        }

        if (fetchAsyncKeys.length) {
            searchNames = await this.ResourceService.getSearchNames({
                pharmaceuticalForm: SEARCHED.pharmaceuticalForm,
                routeOfAdministration: SEARCHED.routeOfAdministration,
                atcCode: SEARCHED.atcCode
            });
            for (const key in searchNames) {
                const value = searchNames[key];
                result.push({
                    // title: this.TranslatePipe.transform(`tagname_${key}`) + `: ${value}`,
                    title: this.TranslatePipe.transform(`tagname_${key}`),
                    value: value,
                    key: key
                });
                // this.storeSearchName({ item: { title: value, value: SEARCHED[key] } }, key);
                this.storeSearchName({ item: { title: value, value: SEARCHED[key] } }, key);
            }
        }
        for (const key in SEARCHED) {
            const value = SEARCHED[key];
            let title;
            if (value === '' || value === null || value === undefined) {
                continue;
            }
            switch (key) {
                // no display
                case 'environment':
                    if (value === 'all') {
                        title = this.TranslatePipe.transform('misc_all');
                    } else title = this.ResourceService.getResourceById('environment', value).title;
                    break;
                case 'event':
                    title = this.ResourceService.getResourceById('event_type', value).title;
                    break;
                case 'hasAttachment':
                case 'isError':
                    title = this.ResourceService.getYesNoOption(value).title;
                    break;
                case 'bounce_on_from':
                case 'bounce_on_from':
                case 'event_on_from':
                case 'event_on_from':
                case 'date_from':
                case 'date_to':
                    title = moment(value).format('DD-MM-YYYY');
                    break;
                case 'projectId':
                    title =
                        this.AuthenticationService.getUserValue()['projects'].find((item) => {
                            return item.value === value;
                        }).title || value;
                    break;
                default:
                    title = value;
                    break;
            }
            // skip defaults
            if (initialSEARCH[key] && initialSEARCH[key] === value) {
                title = null;
            }
            if (title) {
                result.push({
                    title: this.TranslatePipe.transform(`tagname_${key}`),
                    value: title,
                    key: key
                });
            }
        }
        return result;
    }

    getSortingClass(code: string, SORT: any) {
        if (SORT.code == code) {
            return SORT.dir;
        } else return '';
    }

    getCleanSearch(SEARCH: any) {
        const searchParams = Object.assign({}, SEARCH);
        for (const key in searchParams) {
            const value = searchParams[key];
            if (value === null || value === undefined || value === '') {
                delete searchParams[key];
            }
            // switch (key) {
            //     case 'usage':
            //         delete searchParams[key];
            //         break;
            //     case 'list':
            //         delete searchParams[key];
            //         break;
            // }
        }
        // search
        if (this.HelpersService.objectIsEmpty(searchParams)) {
            return;
        }
        return searchParams;
    }

    getCleanSort(SORT: any, initialSort: any) {
        if (this.HelpersService.objectIsEmpty(SORT) || (SORT.code == initialSort.code && SORT.dir == initialSort.dir)) {
            return;
        } else return SORT;
    }

    getCleanPage(startRow, RPP) {
        const page = (Math.ceil(startRow / RPP) + 1).toString();
        if (page == '1') {
            return;
        } else return page;
    }

    getSort(code: string, SORT: any, dir?: string) {
        let result = SORT;
        if (SORT.code === code) {
            result.dir = SORT.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: code, dir: 'asc' };
        }
        if (dir) result.dir = dir;
        return result;
    }

    removeTag(key: string, SEARCH: any, searchFn: Function) {
        const result = { ...SEARCH };
        delete result[key];
        SEARCH = result;
        searchFn(false, SEARCH);
    }

    getSearchBody(SEARCH: any, SORT: any, RPP: number, startRow: number) {
        const result = {
            ...Object.assign({}, SEARCH),
            RPP: RPP,
            startRow: startRow,
            orderBy: [`${SORT.code} ${SORT.dir}`]
        };
        return result;
    }
}
