import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import 'moment/locale/nl-be';
moment.locale('nl-be');
import { TranslatorService } from '../services/translator.service';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

const keywordAt = 'om';
const keywordToday = 'vandaag';
const today = moment();

@Pipe({ name: 'formatTs' })
export class formatTsPipe implements PipeTransform {
    constructor(private TranslatePipe: TranslatePipe) { }
    transform(ts: any, format: string, unix: boolean): string {
        let momentObj = !unix ? moment(ts) : moment.unix(ts);

        if (!ts || !format || !momentObj.isValid()) {
            return ts || '-';
        }
        switch (format) {
            case 'prettyTs':
                return momentObj.calendar(null, {
                    sameDay: `[${this.TranslatePipe.transform('misc_today')}]`,
                    nextDay: `[${this.TranslatePipe.transform('misc_tomorrow')}]`,
                    nextWeek: 'DD-MM-YYYY',
                    lastDay: `[${this.TranslatePipe.transform('misc_yesterday')}]`,
                    lastWeek: 'DD-MM-YYYY',
                    sameElse: 'DD-MM-YYYY'
                });
            case 'prettyDateTime':
                return momentObj.calendar(null, {
                    sameDay: `[${this.TranslatePipe.transform('misc_today')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    nextDay: `[${this.TranslatePipe.transform('misc_tomorrow')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    nextWeek: `DD-MM-YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`,
                    lastDay: `[${this.TranslatePipe.transform('misc_yesterday')}] [${this.TranslatePipe.transform(
                        'misc_at'
                    )}] HH:mm`,
                    lastWeek: `DD-MM-YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`,
                    sameElse: `DD-MM-YYYY [${this.TranslatePipe.transform('misc_at')}] HH:mm`
                });
            default:
                break;
        }
        return moment(ts).format(format);
    }
}

@Pipe({ name: 'display' })
export class displayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        return value;
    }
}

@Pipe({ name: 'eventColor' })
export class eventColorPipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 'click':
            case 'open':
                return '#909090';
            case 'delivered':
                return '#909090';
            case 'processed':
                return '#909090';
            case 'sent':
                return '#909090';
            case 'spamreport':
            case 'dropped':
            case 'bounce':
                return '#EA525B';
            case 'deferred':
                return '#ea9e52';
            default:
                return '#909090';
        }
    }
}

@Pipe({ name: 'floatDisplay' })
export class floatDisplayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseFloat(value))) {
            let r = parseFloat(value).toString().replace(/\./g, ',');
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({ name: 'numberDisplay' })
export class numberDisplayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        }

        if (!isNaN(parseInt(value))) {
            let r = parseInt(value).toString();
            if (type && type == 'thousandSeparator') {
                return r.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            } else {
                return r;
            }
        }

        return value;
    }
}

@Pipe({ name: 'customCurrency' })
export class customCurrencyPipe implements PipeTransform {
    constructor(private cp: CurrencyPipe) { }
    transform(value: any, round?: boolean): any {
        if (value === 0 || value === '0') {
            return round ? '€ 0' : '€ 0,00';
        }
        if (!value) {
            return '-';
        } else {
            let returnValue = this.cp.transform(parseFloat(value).toString(), '€ ').replace(/,/g, '.');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            if (round) {
                returnValue = returnValue.substring(0, returnValue.length - 3);
            }
            return returnValue;
        }
    }
}
@Pipe({ name: 'customPercentage' })
export class percentagePipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '0%';
        }
        if (!value) {
            return '-';
        } else return value.toString().replace('.', ',') + '%';
    }
}

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) { }
    public transform(key: string): string {
        return this.TranslatorService.getTranslation(key);
    }
}

@Pipe({ name: 'array' })
export class ArrayPipe implements PipeTransform {
    constructor() { }
    public transform(arr: any[]): string {
        if (!arr?.length) return '-';
        return arr.join(', ');
    }
}

@Pipe({
    name: 'safe'
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) { }

    public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                throw new Error(`Invalid safe type specified: ${type}`);
        }
    }
}

@Pipe({
    name: 'callback',
    pure: false
})
export class callbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, data?: any) => boolean, data?): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter((item) => callback(item, data));
    }
}

type unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
    [u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
    bytes: 0,
    KB: 0,
    MB: 1,
    GB: 1,
    TB: 2,
    PB: 2
};
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    private readonly units: unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    transform(bytes: number = 0, precision: number | unitPrecisionMap = defaultPrecisionMap): string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

        let unitIndex = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unitIndex++;
        }

        const unit = this.units[unitIndex];

        if (typeof precision === 'number') {
            return `${bytes.toFixed(+precision)} ${unit}`;
        }
        return `${bytes.toFixed(precision[unit])} ${unit}`;
    }
}
