import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { ResourceService } from 'src/app/services/resource.service';
import { SearchService } from 'src/app/services/search.service';
import { eventColorPipe, TranslatePipe } from 'src/app/utils/pipes';
import { HttpClient } from '@angular/common/http';
import { ProjectModalComponent } from 'src/app/components/modals/project/project-modal.component';
import { DefaultService } from 'src/app/utils/api';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
    localItems: any[] = null;
    items: any[] = null;
    SEARCH: any = {};
    SEARCHED: any[] = [];
    SORT: any = {};
    RPP: number = 40;
    startRow: number = 0;
    totalRows: number = undefined;
    previousParams: any = null;
    typeaheadModels: any = {
        project: null
    };
    user: any = null;
    options: any = {};
    helper: any = {};
    subscriptions: any = {};
    internal: boolean = false;

    // table
    heads: any;
    data: any;

    ready: boolean = false;
    loading: boolean = false;

    selectedItemId: any = null;
    selectedItemLoading: boolean = true;
    selectedItem: any = null;

    advancedSearchOpen: boolean = false;

    constructor(
        private ModalService: NgbModal,
        public Route: ActivatedRoute,
        private Router: Router,
        public HelpersService: HelpersService,
        public SearchService: SearchService,
        private TranslatePipe: TranslatePipe,
        private EventColorPipe: eventColorPipe,
        private AuthenticationService: AuthenticationService,
        public ResourceService: ResourceService,
        private http: HttpClient,
        private DefaultService: DefaultService,
        private toastr: ToastrService
    ) {
        // get local items
        // this.init();

        this.Route.queryParams.subscribe((params: any) => {
            const parameters = Object.assign({}, params);
            if (parameters.id) delete parameters['id'];
            if (parameters.updateoverview) {
                this.getItems();
                this.Router.navigate([], {
                    queryParams: {
                        updateoverview: null
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: true
                });
                return;
            }
            if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
                return;
            }
            this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
            this.SEARCH = parameters.search
                ? this.getInitialSearch(JSON.parse(parameters.search))
                : this.getInitialSearch();
            this.SORT = parameters.sort ? JSON.parse(parameters.sort) : this.getInitialSort();
            let code = undefined;
            if (parameters.page !== this.previousParams?.page || this.previousParams?.page !== undefined)
                code = 'changeStartRow';
            this.getItems(code);
            this.previousParams = parameters;
        });
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
            if (
                next.roles.find((item) => {
                    return item === 'internal';
                })
            ) {
                this.internal = true;
            } else this.internal = false;
        });
    }

    ngOnInit(): void {
        this.initHeads();
    }

    getInitialSearch(SEARCH?: any) {
        if (SEARCH) {
            return {
                ...this.getDefaultSearch(),
                ...SEARCH
            };
        } else return this.getDefaultSearch();
    }

    getDefaultSearch() {
        return {
            // environment: this.ResourceService.getResourceByCode('environment', 'production').id
        };
    }

    getInitialSort() {
        return {
            code: 'name',
            dir: 'asc'
        };
    }

    clickSort(code: string, dir?: string) {
        this.Router.navigate([], {
            queryParams: {
                sort: JSON.stringify(
                    this.SearchService.getCleanSort(
                        this.SearchService.getSort(code, this.SORT, dir),
                        this.getInitialSort()
                    )
                )
            },
            queryParamsHandling: 'merge'
        });
    }

    search = (reset?: boolean, SEARCH?: any, code?: string) => {
        this.advancedSearchOpen = false;
        if (code === 'enter') {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(SEARCH || this.SEARCH)),
                    page: null,
                    updateoverview: 'yes'
                },
                queryParamsHandling: 'merge'
            });
        } else if (reset) {
            this.Router.navigate([], {
                queryParams: {
                    search: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(SEARCH || this.SEARCH)),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    };

    advancedSearch(SEARCH) {
        this.SEARCH = { ...this.SEARCH, ...SEARCH, term: '' };
        this.search();
    }

    resetStartRow() {
        this.Router.navigate([], {
            queryParams: {
                page: null
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(newStartRow: number) {
        this.Router.navigate([], {
            queryParams: {
                page: this.SearchService.getCleanPage(newStartRow, this.RPP)
            },
            queryParamsHandling: 'merge'
        });
    }

    // END SEARCH

    // START CUSTOM

    initHeads() {
        this.heads = [
            {
                name: this.TranslatePipe.transform('projects_th_name'),
                code: 'name',
                sortable: false,
                width: '25%'
            },
            {
                name: this.TranslatePipe.transform('projects_th_client'),
                code: 'client',
                sortable: false,
                width: '25%'
            },
            {
                name: this.TranslatePipe.transform('projects_th_group'),
                code: 'lnk_group_id',
                sortable: true,
                width: '20%'
            },
            {
                name: '',
                code: 'actions',
                width: ''
            }
        ];
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'remove':
                if (confirm(this.TranslatePipe.transform('toast_project_delete_confirm'))) {
                    this.DefaultService.projectsDeleteProject(item.id).subscribe((next) => {
                        this.getItems();
                        this.toastr.success(
                            this.TranslatePipe.transform('toast_project_deleted_msg'),
                            this.TranslatePipe.transform('toast_project_deleted_title')
                        );
                    });
                }

                break;
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
        }
    }

    getItems = (code?: string) => {
        this.SearchService.formatTags({ ...this.SEARCH }, this.getDefaultSearch()).then(
            (response) => {
                this.SEARCHED = response;
                this.loading = true;
                const SORT = { ...this.SORT };
                const SEARCH = this.SearchService.getSearchBody(this.SEARCH, SORT, this.RPP, this.startRow);
                if (SEARCH?.environment === 'all') delete SEARCH.environment;
                this.subscriptions?.getItems?.unsubscribe();
                this.subscriptions.getItems = this.DefaultService.projectsGetProjects(
                    SEARCH.startRow,
                    SEARCH.RPP,
                    SEARCH.orderBy,
                    SEARCH.term
                ).subscribe(
                    (next: any) => {
                        this.items = next.data.map((i) => {
                            return {
                                ...i,
                                actions: {
                                    type: 'actions',
                                    actions: [
                                        {
                                            name: this.TranslatePipe.transform('projects_action_remove'),
                                            code: 'remove',
                                            icon: 'trash'
                                        },
                                        {
                                            name: this.TranslatePipe.transform('projects_action_edit'),
                                            code: 'edit',
                                            icon: 'pencil'
                                        }
                                    ]
                                }
                            };
                        });
                        this.totalRows = next.rows;
                        this.ready = true;
                        this.loading = false;
                        if (code === 'changeStartRow') this.HelpersService.toTop();
                    },
                    (error) => {
                        // this.clickSort(this.getInitialSort().code, this.getInitialSearch().dir);
                        // setTimeout(() => {
                        //     this.search(true);
                        // }, 1);
                    }
                );
            },
            (error) => {
                // this.clickSort(this.getInitialSort().code, this.getInitialSearch().dir);
                // setTimeout(() => {
                //     this.search(true);
                // }, 1);
            }
        );
    };

    clearTypeaheads() {
        this.typeaheadModels = {
            project: null
        };
    }

    handleTypeaheads() {
        this.clearTypeaheads();
        if (this.SEARCH['project']) {
            this.typeaheadModels['project'] = {
                title:
                    this.AuthenticationService.getUserValue()['projects'].find((item) => {
                        return item.value === this.SEARCH['project'];
                    }).title || this.SEARCH['project'],
                value: this.SEARCH['project']
            };
        }
    }

    openCreateUpdateModal(id?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(ProjectModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('id', id);
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        }
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.getItems();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('id');
            });
        modalRef.componentInstance.updated.subscribe((discount: any) => {
            this.getItems();
        });
    }
    // END CUSTOM
}
