<div class="field field-date" [class.position-top]="positionDateSelector == 'top'" >
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="w-100 position-relative">
        <input
            [ngStyle]="{'width': width ? width : ''}"
            #myinput 
            [class.error-field]="error"
            [class.invalid-field]="invalidFieldStyle()"
            [placeholder]="placeholder" 
            (calendarToggle)="calendarToggle($event)"
            angular-mydatepicker 
            name="mydate" 
            (click)="dp.toggleCalendar()" 
            (blur)="onBlur()"
            (focus)="onFocus()"
            (inputFieldChanged)="onInputChange($event)"
            (dateChanged)="onDateChanged($event)"
            [(ngModel)]="localModel" 
            (ngModelChange)="changes()"
            [options]="datepickerOptions" 
            [locale]="locale"
            #dp="angular-mydatepicker"/>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
    <!-- ->{{model}}<br>
    ->{{localModel}}<br>
    ->{{inputValue}}<br> <br>
    -> calendarIsOpen: {{calendarIsOpen}} <br>
    -> isFocused: {{isFocused}} -->
</div>