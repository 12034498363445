import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ResourceService } from './resource.service';
import { TranslatorService } from './translator.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    constructor(
        private ResourceService: ResourceService,
        private TranslatorService: TranslatorService,
        public AuthenticationService: AuthenticationService
    ) { }

    initialize() {
        return new Promise<void>((resolve, reject) => {
            this.AuthenticationService.fetchUser().subscribe((next) => {
                const observables$: Observable<any>[] = [Observable.of(null)];
                observables$.push(...this.ResourceService.init());
                observables$.push(this.TranslatorService.init(next.language));
                forkJoin(observables$).subscribe((next) => {
                    resolve();
                });
            });
        });
    }
}
