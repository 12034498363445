<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready || !resourcesReady">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="key" class="d-flex detail-modal">
        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready && resourcesReady">
                <div class="">
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;"> {{id ? ('Key '+key.username) : 'New key'}}</h1>
                        <div class="h-100 d-flex align-items-center">
                            <button class="button tertiary" (click)="canExit() && ActiveModal.dismiss()" [class.disabled]="saving"><span>Annuleer</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaar" [disabled]="saving" [submitting]="submitting" (emitClick)="createUpdateDiscount()"></app-button>
                        </div>
                    </div>
                    
                    <div class="detail-modal-body">
                        <!-- content -->
                        <div class="row display-row">
                            <div class="col-xl-4">
                                <!-- type -->
                                <div class="display-content">
                                    <ng-container *ngIf="key.id">
                                        <div class="display-label">Username</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{formSTR.username | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!key.id">
                                        <app-field-text 
                                            [model]="formSTR.username" 
                                            (modelChange)="formSTR.username = $event; formChange();" 
                                            label="Username"
                                            placeholder=""
                                            [error]="validation.username">
                                        </app-field-text>
                                    </ng-container>       
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-xl-4">
                                <!-- type -->
                                <div class="display-content">
                                    <ng-container>
                                        <app-field-select 
                                            [model]="formSTR.lnk_project_id" 
                                            [options]="projectARR"
                                            (modelChange)="formSTR.lnk_project_id = $event; formChange();" 
                                            label="Project"
                                            [error]="validation.lnk_project_id"
                                            placeholder="">
                                        </app-field-select>
                                    </ng-container>       
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-xl-4">
                                <!-- type -->
                                <div class="display-content">
                                    <ng-container>
                                        <app-field-select 
                                            [model]="formSTR.lnk_environment_id" 
                                            [options]="envARR"
                                            (modelChange)="formSTR.lnk_environment_id = $event; formChange();" 
                                            label="Environment"
                                            [error]="validation.lnk_environment_id"
                                            placeholder="">
                                        </app-field-select>
                                    </ng-container>       
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-xl-4">
                                <!-- type -->
                                <div class="display-content">
                                    <ng-container *ngIf="key.id">
                                        <div class="display-label">Credential description</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{formSTR.credentialDescription | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!key.id">
                                        <app-field-select 
                                            [model]="formSTR.credentialId" 
                                            [options]="credentialARR"
                                            (modelChange)="formSTR.credentialId = $event; formChange();" 
                                            label="Credential"
                                            [error]="validation.credentialId"
                                            placeholder="">
                                        </app-field-select>
                                    </ng-container>       
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-xl-4">
                                <!-- type -->
                                <div class="display-content">
                                    <ng-container>
                                        <app-field-text 
                                            [model]="formSTR.description" 
                                            (modelChange)="formSTR.description = $event; formChange();" 
                                            label="Description"
                                            placeholder=""
                                            [error]="validation.description">
                                        </app-field-text>
                                    </ng-container>       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>