<div class="page">
    <div class="title-wrap grid-span-all">
        <h1 class="grid-span-all">{{'dashboard_title' | translate}}</h1>
        <div class="profile-wrap d-flex align-items-center">
            <app-profile-section></app-profile-section>
        </div>
    </div>
    <div class="grid">
        <!-- stats today -->
        <h2 class="grid-span-all">{{'dashboard_today' | translate}}</h2>
        <div class="panel panel-stat panel-stat--sent">
            <div class="title"
                [ngStyle]="{'color': ('sent' | eventColor)}">{{'dashboard_sent'
                | translate}}</div>
            <ng-container *ngIf="statsToday.sent !== undefined"><div
                    class="value">{{statsToday.sent || 0}}</div></ng-container>
            <ng-container *ngIf="statsToday.sent === undefined"><app-spinner
                    [noMargin]="true"
                    [alignLeft]="true"></app-spinner></ng-container>
        </div>
        <div class="panel panel-stat panel-stat--delivered">
            <div class="title"
                [ngStyle]="{'color': ('delivered' | eventColor)}">{{'dashboard_delivered'
                | translate}}</div>
            <ng-container *ngIf="statsToday.delivered !== undefined"><div
                    class="value">{{statsToday.delivered ||
                    0}}</div></ng-container>
            <ng-container
                *ngIf="statsToday.delivered === undefined"><app-spinner
                    [noMargin]="true"
                    [alignLeft]="true"></app-spinner></ng-container>
        </div>
        <div class="panel panel-stat panel-stat--opened">
            <div class="title"
                [ngStyle]="{'color': ('open' | eventColor)}">{{'dashboard_opened'
                | translate}}</div>
            <ng-container *ngIf="statsToday.opened !== undefined"><div
                    class="value">{{statsToday.opened ||
                    0}}</div></ng-container>
            <ng-container *ngIf="statsToday.opened === undefined"><app-spinner
                    [noMargin]="true"
                    [alignLeft]="true"></app-spinner></ng-container>
        </div>
        <div class="panel panel-stat panel-stat--bounced">
            <div class="title"
                [ngStyle]="{'color': ('bounce' | eventColor)}">{{'dashboard_bounced'
                | translate}}</div>
            <ng-container *ngIf="statsToday.bounced !== undefined"><div
                    class="value">{{statsToday.bounced ||
                    0}}</div></ng-container>
            <ng-container *ngIf="statsToday.bounced === undefined"><app-spinner
                    [noMargin]="true"
                    [alignLeft]="true"></app-spinner></ng-container>
        </div>
        <!-- stats -->
        <h2 class="grid-span-all">{{'dashboard_stats' | translate}} <span
                class="range" [class.saving]="!ready"><span
                    [class.saving]="loading" class="icon-wrap"
                    (click)="prevMonth()"><i
                        class="im-icon im-icon-chev-left"></i></span>{{dateRange}}<span
                    [class.saving]="loading || !allowNextMonth"
                    class="icon-wrap" (click)="nextMonth()"><i
                        class="im-icon im-icon-chev-right"></i></span></span></h2>
        <div class="panels-left">
            <!-- projects -->
            <div class="panel panel-data panel-data--projects"
                [class.loading]="!ready" [class.saving]="loading">
                <div class="title">{{'dashboard_projects' | translate}}</div>
                <!-- table -->
                <ng-container *ngIf="ready && projectStats?.length">
                    <table class="table--detail table--detail--dash">
                        <tr>
                            <th width="38%">{{'dashboard_th_project' |
                                translate}}</th>
                            <th width="30%">{{'dashboard_th_environment' |
                                translate}}</th>
                            <th width="16%">{{'dashboard_th_sent' |
                                translate}}</th>
                            <!-- <th width="16%">{{'dashboard_th_bounced' | translate}}</th> -->
                        </tr>
                        <ng-container *ngFor="let project of projectStats">
                            <tr>
                                <td>{{project.project}}</td>
                                <td
                                    class="td-passive">{{project.environment}}</td>
                                <td class="td-passive">{{project.sent}}</td>
                                <!-- <td class="td-passive">{{project.bounced}}</td> -->
                            </tr>
                        </ng-container>
                    </table>
                    <button (click)="export('projects')"
                        [class.disabled]="saving.projects"
                        class="button-link fontsize-s export-btn">
                        <i *ngIf="!saving.projects"
                            class="im-icon im-icon-download"></i>
                        <app-inline-spinner *ngIf="saving.projects"
                            class="mr-1"></app-inline-spinner>
                        <span class="underline">{{'dashboard_export_btn' |
                            translate}}</span>
                    </button>
                </ng-container>
                <!-- empty -->
                <ng-container *ngIf="ready && !projectStats?.length">
                    <app-empty-state
                        [value]="'dashboard_emptystate' | translate"></app-empty-state>
                </ng-container>
                <!-- spinner -->
                <ng-container *ngIf="!ready">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </ng-container>
            </div>
            <!-- clients -->
            <div class="panel panel-data panel-data--clients" *ngIf="internal"
                [class.loading]="!ready" [class.saving]="loading">
                <div class="title">{{'dashboard_clients' | translate}}</div>
                <!-- table -->
                <ng-container *ngIf="ready && clientStats?.length">
                    <table class="table--detail table--detail--dash">
                        <tr>
                            <th width="68%">{{'dashboard_th_client' |
                                translate}}</th>
                            <th width="16%">{{'dashboard_th_sent' |
                                translate}}</th>
                            <!-- <th width="16%">{{'dashboard_th_bounced' |
                                translate}}</th> -->
                        </tr>
                        <ng-container *ngFor="let client of clientStats">
                            <tr>
                                <td>{{client.client}}</td>
                                <td class="td-passive">{{client.sent}}</td>
                                <!-- <td class="td-passive">{{client.bounced}}</td> -->
                            </tr>
                        </ng-container>
                    </table>
                    <button (click)="export('clients')"
                        [class.disabled]="saving.clients"
                        class="button-link fontsize-s export-btn">
                        <i *ngIf="!saving.clients"
                            class="im-icon im-icon-download"></i>
                        <app-inline-spinner *ngIf="saving.clients"
                            class="mr-1"></app-inline-spinner>
                        <span class="underline">{{'dashboard_export_btn' |
                            translate}}</span>
                    </button>
                </ng-container>
                <!-- empty -->
                <ng-container *ngIf="ready && !clientStats?.length">
                    <app-empty-state
                        [value]="'dashboard_emptystate' | translate"></app-empty-state>
                </ng-container>
                <!-- spinner -->
                <ng-container *ngIf="!ready">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </ng-container>
            </div>
        </div>
        <div class="panels-right" *ngIf="false">
            <!-- activity -->
            <div class="panel panel-data panel-data--activity">
                <div class="title">{{'dashboard_activity' | translate}}</div>
                Tons of activity...
            </div>
        </div>
    </div>
</div>