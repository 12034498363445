import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../../services/resource.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ILeaveComponent } from 'src/app/services/leave-guard.service';
import { takeUntil, finalize, tap } from 'rxjs/operators';
import { customCurrencyPipe, percentagePipe } from '../../../utils/pipes';
import { HelpersService } from '../../../services/helpers.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-key-modal',
    templateUrl: './key-modal.component.html',
    styleUrls: ['./key-modal.component.scss']
})
export class KeyModalComponent implements OnInit {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    key: any = null;
    pages: { code: any; name: any; notify?: boolean }[];
    form: any;
    popoverHelper: any = null;
    validation: any = {};
    changes: boolean = false;
    projectARR: any[];
    credentialARR: any[];
    envARR: any[];

    ready: boolean = false;
    resourcesReady: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = {};

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        public ActiveModal: NgbActiveModal,
        public ResourceService: ResourceService,
        private percentagePipe: percentagePipe,
        private customCurrencyPipe: customCurrencyPipe,
        private DefaultService: DefaultService,
        public HelpersService: HelpersService
    ) { }

    ngOnInit(): void {
        // edit or create
        if (this.id) {
            this.getKey();
        } else {
            this.key = {};
            this.ready = true;
        }
        // fetch resources
        this.initResources();
    }

    getKey() {
        this.loading = true;
        this.DefaultService.keysGetKeys(null, null, null, null, this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next: any) => {
                this.key = next.data[0];
                this.resetForm();
                this.ready = true;
                this.loading = false;
            });
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = { ...this.key };
    }

    createUpdateDiscount() {
        const FORM = { ...this.formSTR };
        if (FORM.type != 'unique') {
            delete FORM.uniqueAmount;
        }
        if (FORM.discountOn != 'category') {
            delete FORM.category;
        }
        if (FORM.discountOn != 'product') {
            delete FORM.product;
        }
        if (this.id) {
            FORM.id = this.id;
        }
        this.saving = true;
        this.submitting = true;
        this.DefaultService.keysInsertOrUpdateKey(FORM)
            .pipe(
                finalize(() => {
                    this.saving = false;
                    this.submitting = false;
                })
            )
            .subscribe(
                (next) => {
                    if (next.password) {
                        alert('Password for this new key is: ' + next.password);
                    }
                    this.validation = {};
                    this.updated.emit(this.key);
                    this.changes = false;
                    this.ActiveModal.dismiss();
                },
                (error) => {
                    this.validation = error.error.details;
                }
            );
    }

    initResources() {
        const observables$: Observable<any>[] = [];

        const productsObservable$ = this.DefaultService.projectsGetProjects().pipe(
            tap((next: any) => {
                this.projectARR = [
                    { title: 'Selecteer...', value: null },
                    ...next.data.map((i) => {
                        return {
                            title: i.name,
                            value: i.id
                        };
                    })
                ];
            })
        );
        observables$.push(productsObservable$);
        const credentialObservable$ = this.DefaultService.keysGetCredentials().pipe(
            tap((next: any) => {
                this.credentialARR = [
                    { title: 'Selecteer...', value: null },
                    ...next.map((i) => {
                        return {
                            title: i.description,
                            value: i.id
                        };
                    })
                ];
            })
        );
        observables$.push(credentialObservable$);

        this.envARR = [{ title: 'Selecteer...', value: null }, ...this.ResourceService.getResource('environment')];

        forkJoin(observables$).subscribe((next) => {
            this.resourcesReady = true;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
