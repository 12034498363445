import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldToggleComponent } from './components/fields/field-toggle/field-toggle.component';
import { FieldTextareaComponent } from './components/fields/field-textarea/field-textarea.component';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { ApiModule, Configuration, ConfigurationParameters } from './utils/api';
import { ProjectModalComponent } from './components/modals/project/project-modal.component';
import { ClientModalComponent } from './components/modals/client/client-modal.component';
import { KeyModalComponent } from './components/modals/key/key-modal.component';
import { AvatarComponent } from './components/common/avatar/avatar.component';
import { PopoutPanelComponent } from './components/layout/popout-panel/popout-panel.component';
import { FieldSwitchComponent } from './components/fields/field-switch/field-switch.component';
import { FilterButtonComponent } from './components/common/filter-button/filter-button.component';
import { TableComponent } from './components/common/table/table.component';
import { PopoverComponent } from './components/common/popover/popover.component';
import { ClickOutsideDirective } from './utils/directives';
import {
    displayPipe,
    eventColorPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    ArrayPipe,
    callbackPipe,
    SafePipe,
    floatDisplayPipe,
    numberDisplayPipe,
    FileSizePipe
} from './utils/pipes';
import { FilterButtonGroupComponent } from './components/common/filter-button-group/filter-button-group.component';
import { EmptyStateComponent } from './components/common/empty-state/empty-state.component';
import { GlobalErrorHandler } from './utils/errorHandler';
import { ModalSidePanelComponent } from './components/layout/modal-side-panel/modal-side-panel.component';
import { SideNavigationComponent } from './components/common/side-navigation/side-navigation.component';
import { FieldSelectComponent } from './components/fields/field-select/field-select.component';
import { ShimmerComponent } from './components/misc/shimmer/shimmer.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ModeSwitchComponent } from './components/misc/mode-switch/mode-switch.component';
import { PaginationComponent } from './components/misc/pagination/pagination.component';
import { FieldHorizontalRadioComponent } from './components/fields/field-horizontal-radio/field-horizontal-radio.component';
import { FieldRadiosComponent } from './components/fields/field-radios/field-radios.component';
import { FieldDateComponent } from './components/fields/field-date/field-date.component';
import { FieldCurrencyComponent } from './components/fields/field-currency/field-currency.component';
import { InlineSpinnerComponent } from './components/misc/inline-spinner/inline-spinner.component';
import { FieldCheckboxesComponent } from './components/fields/field-checkboxes/field-checkboxes.component';
// import { FieldRichtextComponent } from './components/fields/field-richtext/field-richtext.component';
// import { QuillModule } from 'ngx-quill';
import { FieldPasswordCheckComponent } from './components/fields/field-password-check/field-password-check.component';
import { NgxDropzoneModule } from './components/common/ngx-dropzone/public_api';
import { FieldDropzoneComponent } from './components/fields/field-dropzone/field-dropzone.component';
import { FieldTimeComponent } from './components/fields/field-time/field-time.component';
import { FieldMultiInputComponent } from './components/fields/field-multi-input/field-multi-input.component';
import { ConfirmActionComponent } from './components/misc/confirm-action/confirm-action.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NavigationComponent } from './components/layout/navigation/navigation.component';
import { MailsComponent } from './pages/mails/mails.component';
import { EventsComponent } from './pages/events/events.component';
import { ProfileSectionComponent } from './components/layout/profile-section/profile-section.component';
import { FieldTypeaheadComponent } from './components/fields/field-typeahead/field-typeahead.component';
import { BlocklistComponent } from './pages/blocklist/blocklist.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { KeysComponent } from './pages/keys/keys.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { MailSlideoutComponent } from './components/layout/mail-slideout/mail-slideout.component';
import { SlideoutWrapComponent } from './components/layout/slideout-wrap/slideout-wrap.component';
import { ButtonComponent } from './components/common/button/button.component';
import { InitService } from './services/init.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SpinnerComponent,
        FieldTextComponent,
        FieldToggleComponent,
        FieldTextareaComponent,
        AvatarComponent,
        PopoutPanelComponent,
        FieldSwitchComponent,
        FilterButtonComponent,
        TableComponent,
        PopoverComponent,
        ClickOutsideDirective,
        formatTsPipe,
        displayPipe,
        eventColorPipe,
        floatDisplayPipe,
        numberDisplayPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        ArrayPipe,
        callbackPipe,
        FileSizePipe,
        SafePipe,
        FilterButtonGroupComponent,
        EmptyStateComponent,
        ModalSidePanelComponent,
        SideNavigationComponent,
        FieldSelectComponent,
        ShimmerComponent,
        ModeSwitchComponent,
        PaginationComponent,
        FieldHorizontalRadioComponent,
        FieldRadiosComponent,
        FieldDateComponent,
        FieldCurrencyComponent,
        InlineSpinnerComponent,
        FieldCheckboxesComponent,
        FieldPasswordCheckComponent,
        FieldDropzoneComponent,
        FieldTimeComponent,
        FieldMultiInputComponent,
        ConfirmActionComponent,
        DashboardComponent,
        NavigationComponent,
        MailsComponent,
        EventsComponent,
        ProfileSectionComponent,
        FieldTypeaheadComponent,
        BlocklistComponent,
        KeysComponent,
        KeyModalComponent,
        ProjectsComponent,
        ProjectModalComponent,
        ClientsComponent,
        ClientModalComponent,
        MailSlideoutComponent,
        SlideoutWrapComponent,
        ButtonComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AngularMyDatePickerModule,
        // QuillModule.forRoot(),
        // SortablejsModule.forRoot({ animation: 150 }),
        NgxDropzoneModule,
        CommonModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        NgxMaskModule.forRoot(options),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [InitService] },
        eventColorPipe,
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
