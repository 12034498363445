import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { TranslatePipe } from '../utils/pipes';
@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    params: any;
    busy$ = new BehaviorSubject<boolean>(false);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private TranslatePipe: TranslatePipe
    ) {
        route.queryParams.subscribe((next: any) => {
            this.params = next;
        });
    }

    public getParam(param: string | number): any {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param.toString()];
    }
    public addParam(param: string | number, value: string | number) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeParam(param: string | number) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    // object is empty or only empty keys (null | undefined | '')
    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] == null || object[key] == undefined || object[key] == '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
    public downloadBlob(blob: Blob, filename: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        let blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }
    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (successMessage) {
            this.toastr.info('', successMessage);
        }
    }

    public isObj(item) {
        return typeof item === 'object' && !Array.isArray(item) && item !== null;
    }

    public toTop(anchor?: string) {
        if (!anchor) {
            window.scrollTo(0, 0);
        } else {
            const el = document.getElementById(anchor);
            if (el) {
                const top = el.getBoundingClientRect().top + window.pageYOffset - 24;
                window.scrollTo({ top: top, behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    public isDefined(item) {
        return item != undefined && item != null;
    }

    public getAndAmountOthersString(array, limit) {
        if (!limit) limit = 2;
        let result = '';
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            result += element.value;
            result += '; ';
            if (i >= limit - 1 && limit != array.length) {
                result +=
                    this.TranslatePipe.transform('misc_and_others_1') +
                    ' ' +
                    (array.length - limit) +
                    ' ' +
                    (array.length - limit === 1
                        ? this.TranslatePipe.transform('misc_and_others_2')
                        : this.TranslatePipe.transform('misc_and_others_2_plural'));
                break;
            }
        }
        return result;
    }

    public setBusy(val) {
        this.busy$.next(val);
    }

    public getBusy() {
        return this.busy$.asObservable();
    }
}
