import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { TranslatePipe } from '../utils/pipes';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    private resources$ = new BehaviorSubject<any>([]);
    user: any = null;

    constructor(
        private DefaultService: DefaultService,
        private AuthenticationService: AuthenticationService,
        private TranslatePipe: TranslatePipe
    ) {
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
    }

    public init(): Observable<any>[] {
        const observables$: Observable<any>[] = [];
        const resources$ = this.DefaultService.resourceGetResources().pipe(
            tap((next: any) => {
                this.resources$.next(
                    next.map((item) => {
                        return {
                            ...item,
                            title: item.label,
                            value: item.id
                        };
                    })
                );
            })
        );
        observables$.push(resources$);
        return observables$;
    }

    public initPublic(): Observable<any>[] {
        const observables$: Observable<any>[] = [Observable.of(null)];
        return observables$;
    }

    public reload(): void {
        forkJoin(this.init()).subscribe((next) => { });
    }

    public getResource(type: string) {
        if (type === 'environment') {
            return this.AuthenticationService.getUserValue().environments;
        }
        if (type === 'yes_no') {
            return this.getYesNoOptions();
        }
        const result = this.resources$.getValue().filter((item) => {
            return item.type === type;
        });
        if (!result?.length) return [{ title: 'notfound', value: 'notfound' }];
        return result;
    }

    public getResourceById(type: string, id: string) {
        if (type === 'environment') {
            return this.AuthenticationService.getUserValue().environments.find((item) => {
                return item.id === id;
            });
        }
        const result = this.resources$.getValue().find((item) => {
            return item.type === type && item.id === id;
        });
        return result || { title: 'notfound', value: 'notfound' };
    }

    public getResourceByCode(type: string, code: string) {
        if (type === 'environment') {
            return this.AuthenticationService.getUserValue().environments.find((item) => {
                return item.code === code;
            });
        }
        const result = this.resources$.getValue().find((item) => {
            return item.type === type && item.code === code;
        });
        return result || { title: 'notfound', value: 'notfound' };
    }

    public getYesNoOptions() {
        return [
            { title: this.TranslatePipe.transform('misc_yes'), value: true },
            { title: this.TranslatePipe.transform('misc_no'), value: false }
        ];
    }

    public getYesNoOption(value) {
        return this.getYesNoOptions().find((item) => {
            return item.value == value;
        });
    }

    public getSearchNames({ pharmaceuticalForm, routeOfAdministration, atcCode }) {
        // return this.DefaultService.resourceGetSearchNames(
        //     pharmaceuticalForm,
        //     routeOfAdministration,
        //     atcCode
        // ).toPromise();
    }

    public getTypeaheadResource(term, type) {
        // return this.DefaultService.resourceGetAutocompleteResource(type, 50, term);
    }
}
