import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Router, RoutesRecognized } from '@angular/router';
import { HelpersService } from './services/helpers.service';
import { TranslatePipe } from './utils/pipes';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    firstRouterCycleDone: boolean = false;
    isPublic: boolean;
    busy: boolean = false;

    constructor(
        private TitleService: Title,
        public Router: Router,
        private HelpersService: HelpersService,
        private TranslatePipe: TranslatePipe
    ) { }

    ngOnInit(): void {
        // listen to router events
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                this.handleRouteChange(route);
                if (this.firstRouterCycleDone) {
                    this.handleRouteChange(route);
                } else {
                    this.firstRouterCycleDone = true;
                    this.setReady(true);
                }
            }
        });
        this.HelpersService.getBusy().subscribe((next) => {
            this.busy = next;
        });
    }

    public handleRouteChange(route) {
        let title = this.TranslatePipe.transform('meta_title');
        let routeTitle = route?.data?.title;
        if (route?.firstChild?.data?.title) routeTitle = route.firstChild.data.title;
        if (routeTitle) {
            const pre = this.TranslatePipe.transform(`meta_title_${route.data.title}`);
            title = `${pre} | ${title}`;
        }
        if (routeTitle !== 'async') this.TitleService.setTitle(title);
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        this.ready$.next(ready);
    }
}
