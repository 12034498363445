import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    collapsed: boolean = false;
    user: any = {};
    collapsedAnimationDone: boolean = true;
    defaultMailSearch: string;
    defaultEventSearch: string;

    constructor(private AuthenticationService: AuthenticationService) {
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
        this.defaultMailSearch = JSON.stringify({
            date_from: new Date().toISOString().split('T').shift()
        });
        this.defaultEventSearch = JSON.stringify({
            event_on_from: new Date().toISOString().split('T').shift()
        });
    }

    ngOnInit(): void {
        if (localStorage.getItem('navigation_is_collapsed') === 'yes') {
            this.collapsed = true;
        } else this.collapsed = false;
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.collapsedAnimationDone = false;
        localStorage.setItem('navigation_is_collapsed', this.collapsed ? 'yes' : 'no');
        setTimeout(() => {
            this.collapsedAnimationDone = true;
        }, 180);
    }
}
