<nav [class.collapsed]="collapsed" [class.collapsed-animation-done]="collapsedAnimationDone">
    <button class="button-collapse" (click)="toggleCollapsed()"><i class="im-icon" [class.im-icon-chev-left]="!collapsed" [class.im-icon-chev-right]="collapsed"></i></button>
    <div class="nav-head">
        <a [routerLink]="['/dashboard']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="logo">
            <img class="hide-collapsed" src="/assets/img/misc/logo.svg" alt="">
            <img class="show-collapsed" src="/assets/img/misc/logo-collapsed.svg" alt="">
        </a>
    </div>
    <div class="nav-body">
        <ul class="d-flex flex-column navigation">
            <li>
                <a [routerLink]="['/dashboard']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-dashboard"></i><span>{{'nav_dashboard' | translate}}</span></a>
            </li>
            <li>
                <a [routerLink]="['/mails']" [queryParams]="{search: defaultMailSearch}" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-mail"></i><span>{{'nav_mails' | translate}}</span></a>
            </li>
            <li>
                <a [routerLink]="['/events']" [queryParams]="{search: defaultEventSearch}" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-email-events"></i><span>{{'nav_events' | translate}}</span></a>
            </li>
            <li>
                <a [routerLink]="['/blocklist']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-email-bounces"></i><span>{{'nav_blocklist' | translate}}</span></a>
            </li>
            <li>
                <a *ngIf="user?.roles?.includes('admin')" [routerLink]="['/projects']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-globe"></i><span>{{'nav_projects' | translate}}</span></a>
            </li>
            <li>
                <a *ngIf="user?.roles?.includes('admin')"  [routerLink]="['/clients']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-member-card"></i><span>{{'nav_clients' | translate}}</span></a>
            </li>
            <li>
                <a *ngIf="user?.roles?.includes('admin')"  [routerLink]="['/keys']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i class="im-icon im-icon-key"></i><span>{{'nav_keys' | translate}}</span></a>
            </li>
        </ul>
    </div>
</nav>

<div [class.collapsed]="collapsed" class="nav-spacer"></div>