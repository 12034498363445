<div class="page">
    <!-- top bar -->
    <section class="top-bar" [class.no-pointer]="loading && false">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap">
                    <!-- search bar -->
                    <app-field-text
                        [model]="SEARCH.term" 
                        (modelChange)="SEARCH.term = $event" 
                        (enter)="search(undefined, undefined, 'enter')"
                        [placeholder]="'keys_search_placeholder' | translate">
                    </app-field-text>
                </div>
            </div>
            <div class="search-sub-wrap d-flex">
                <div class="d-flex align-items-start">
                    <div class="tags" *ngIf="SEARCHED?.length">
                        <div class="tag d-inline-flex align-items-center" *ngFor="let tag of SEARCHED">
                            <span><span class="title">{{tag.title}}: </span><span class="value">{{tag.value}}</span></span>
                            <i class="im-icon im-icon-close-round-filled" (click)="SearchService.removeTag(tag.key, SEARCH, search)"></i>
                        </div>
                    </div>
                    <!-- reset search button -->
                    <button *ngIf="SEARCHED?.length" (click)="search(true)" class="button-link underline">{{'misc_reset_search' | translate}}</button>
                </div>
            </div>
        </div>
        <!-- profile right -->
        <div class="profile-wrap d-flex align-items-center">
            <app-profile-section></app-profile-section>
        </div>
    </section>
    <!-- end top bar -->

    <!-- title -->
    <div class="d-flex justify-content-between">
        <h1 class="">{{'keys_title' | translate}} <span [class.saving]="loading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h1>
        <app-button classes="primary-default" icon="plus-round" text="{{'keys_add_button' | translate}}" [disabled]="false" [submitting]="false" (emitClick)="openCreateUpdateModal()"></app-button>
    </div>
    

    <!-- table -->
    <div class="table-wrap">
        <app-table
            [heads]="heads" 
            [data]="items"
            [sort]="SORT"
            [RPP]="RPP"
            [startRow]="startRow"
            [totalRows]="totalRows"
            [loading]="loading"
            [ready]="ready"
            [timeout]="0"
            [selected]=""
            [emptyState]="'keys_emptystate' | translate"
            (sortChange)="clickSort($event.code, $event.dir)"
            (clickAction)="actionClick($event.item, $event.action)"
            (startRowChange)="changeStartRow($event)"
        ></app-table>
    </div>
</div>