import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() disabled?: boolean = false;
    @Input() submitting?: boolean = false;
    @Input() classes: 'primary-default' | 'primary-light' = 'primary-default';
    @Input() icon?: string = '';
    @Input() iconSize?: string = '16px';
    @Input() text: string = '';
    @Output() emitClick = new EventEmitter();

    constructor() { }

    ngOnInit(): void { }
}
