import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-inline-spinner',
    templateUrl: './inline-spinner.component.html',
    styleUrls: ['./inline-spinner.component.scss']
})
export class InlineSpinnerComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
