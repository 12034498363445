<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready || !resourcesReady">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="client" class="d-flex detail-modal">
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready && resourcesReady">
                <div class="">
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;"> {{id ? ('Client '+client.name) : 'New client'}}</h1>
                        <div class="h-100 d-flex align-items-center">
                            <button class="button tertiary" (click)="canExit() && ActiveModal.dismiss()" [class.disabled]="saving"><span>Cancel</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaar" [disabled]="saving" [submitting]="submitting" (emitClick)="createUpdateDiscount()"></app-button>
                        </div>
                    </div>
                    <div class="detail-modal-body">
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-4">
                                <div class="display-content">
                                    <ng-container>
                                        <app-field-text 
                                            [model]="formSTR.name" 
                                            (modelChange)="formSTR.name = $event; formChange();" 
                                            label="Name"
                                            placeholder=""
                                            [error]="validation.name">
                                        </app-field-text>
                                    </ng-container>       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>