import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from './config.service';

import { tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import { Config } from '../models/common';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);

    constructor(private defaultService: DefaultService) { }

    public fetchUser(): any {
        return this.defaultService.userMe().pipe(
            tap((next: any) => {
                next = {
                    ...next,
                    projects: next.projects.map((item) => {
                        return { ...item, title: item.name, value: item.id };
                    }),
                    environments: next.environments.map((item) => {
                        return { ...item, title: item.label, value: item.id };
                    })
                };
                this.setUser(next);
            })
        );
        // return Observable.of({ firstname: 'Admin', lastname: 'Imengine', language: 'en' }).pipe(
        //     tap((next) => {
        //         this.setUser(next);
        //     })
        // );
    }

    public logout() {
        window.location.href = '/api/user/logout';
    }

    public onUnauth() {
        window.location.href = '/api/user/login';
    }

    public getUserValue(): any {
        return this.user$.getValue() || null;
    }

    public getUser(): Observable<any> {
        return this.user$.asObservable();
    }

    private setUser(user: any) {
        this.user$.next(user);
    }
}
