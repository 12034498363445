import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { ResourceService } from 'src/app/services/resource.service';
import { SearchService } from 'src/app/services/search.service';
import { eventColorPipe, TranslatePipe } from 'src/app/utils/pipes';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { DefaultService } from 'src/app/utils/api';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-blocklist',
    templateUrl: './blocklist.component.html',
    styleUrls: ['./blocklist.component.scss']
})
export class BlocklistComponent implements OnInit {
    localItems: any[] = null;
    items: any[] = null;
    SEARCH: any = {};
    SEARCHED: any[] = [];
    SORT: any = {};
    RPP: number = 40;
    startRow: number = 0;
    totalRows: number = undefined;
    previousParams: any = null;
    typeaheadModels: any = {
        project: null
    };
    user: any = null;
    options: any = {};
    helper: any = {};
    subscriptions: any = {};
    internal: boolean = false;

    // table
    heads: any;
    data: any;

    ready: boolean = false;
    loading: boolean = false;

    selectedItemId: any = null;
    selectedItemLoading: boolean = true;
    selectedItem: any = null;

    advancedSearchOpen: boolean = false;

    constructor(
        public Route: ActivatedRoute,
        private Router: Router,
        public HelpersService: HelpersService,
        public SearchService: SearchService,
        private TranslatePipe: TranslatePipe,
        private EventColorPipe: eventColorPipe,
        private AuthenticationService: AuthenticationService,
        public ResourceService: ResourceService,
        private http: HttpClient,
        private DefaultService: DefaultService,
        private toastr: ToastrService
    ) {
        // get local items
        // this.init();

        this.Route.queryParams.subscribe((params: any) => {
            const parameters = Object.assign({}, params);
            if (parameters.id) delete parameters['id'];
            if (parameters.updateoverview) {
                this.getItems();
                this.Router.navigate([], {
                    queryParams: {
                        updateoverview: null
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: true
                });
                return;
            }
            if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
                return;
            }
            this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
            this.SEARCH = parameters.search
                ? this.getInitialSearch(JSON.parse(parameters.search))
                : this.getInitialSearch();
            this.SORT = parameters.sort ? JSON.parse(parameters.sort) : this.getInitialSort();
            let code = undefined;
            if (parameters.page !== this.previousParams?.page || this.previousParams?.page !== undefined)
                code = 'changeStartRow';
            this.getItems(code);
            this.previousParams = parameters;
        });
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
            if (
                next.roles.find((item) => {
                    return item === 'internal';
                })
            ) {
                this.internal = true;
            } else this.internal = false;
        });
    }

    ngOnInit(): void {
        this.initHeads();
    }

    // init(refresh?) {
    //     this.loading = true;
    //     this.http.get('/api/sendgrid/bounces').subscribe((next: any) => {
    //         this.localItems = next;
    //         if (!refresh) {
    //             this.Route.queryParams.subscribe((params: any) => {
    //                 const parameters = Object.assign({}, params);
    //                 if (parameters.id) delete parameters['id'];
    //                 if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
    //                     return;
    //                 }
    //                 this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
    //                 this.SEARCH = parameters.search
    //                     ? this.getInitialSearch(JSON.parse(parameters.search))
    //                     : this.getInitialSearch();
    //                 this.SORT = parameters.sort ? JSON.parse(parameters.sort) : this.getInitialSort();
    //                 let code = undefined;
    //                 if (parameters.page !== this.previousParams?.page || this.previousParams?.page !== undefined)
    //                     code = 'changeStartRow';
    //                 this.getItems(code);
    //                 this.previousParams = parameters;
    //             });
    //         } else this.getItems();
    //     });
    // }

    // START SEARCH

    getInitialSearch(SEARCH?: any) {
        if (SEARCH) {
            return {
                ...this.getDefaultSearch(),
                ...SEARCH
            };
        } else return this.getDefaultSearch();
    }

    getDefaultSearch() {
        return {
            // environment: this.ResourceService.getResourceByCode('environment', 'production').id
        };
    }

    getInitialSort() {
        return {
            code: 'bounce_ts',
            dir: 'desc'
        };
    }

    clickSort(code: string, dir?: string) {
        this.Router.navigate([], {
            queryParams: {
                sort: JSON.stringify(
                    this.SearchService.getCleanSort(
                        this.SearchService.getSort(code, this.SORT, dir),
                        this.getInitialSort()
                    )
                )
            },
            queryParamsHandling: 'merge'
        });
    }

    search = (reset?: boolean, SEARCH?: any, code?: string) => {
        this.advancedSearchOpen = false;
        if (code === 'enter') {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(SEARCH || this.SEARCH)),
                    page: null,
                    updateoverview: 'yes'
                },
                queryParamsHandling: 'merge'
            });
        } else if (reset) {
            this.Router.navigate([], {
                queryParams: {
                    search: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.Router.navigate([], {
                queryParams: {
                    search: JSON.stringify(this.SearchService.getCleanSearch(SEARCH || this.SEARCH)),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    };

    advancedSearch(SEARCH) {
        this.SEARCH = { ...this.SEARCH, ...SEARCH, term: '' };
        this.search();
    }

    resetStartRow() {
        this.Router.navigate([], {
            queryParams: {
                page: null
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(newStartRow: number) {
        this.Router.navigate([], {
            queryParams: {
                page: this.SearchService.getCleanPage(newStartRow, this.RPP)
            },
            queryParamsHandling: 'merge'
        });
    }

    // END SEARCH

    // START CUSTOM

    initHeads() {
        const heads: any[] = [
            {
                name: this.TranslatePipe.transform('blocklist_th_to'),
                code: 'to',
                sortable: false,
                width: '24%'
            },
            {
                name: this.TranslatePipe.transform('blocklist_th_message'),
                code: 'message',
                sortable: false,
                width: '56%'
            },
            {
                name: this.TranslatePipe.transform('blocklist_th_bounce_ts'),
                code: 'bounce_ts',
                sortable: true,
                width: '20%'
            }
        ];
        if (this.internal) {
            heads.push({
                name: '',
                code: 'actions',
                width: ''
            });
        }
        this.heads = heads;
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'remove':
                this.DefaultService.sendgridDeleteBounce(item.email).subscribe((next) => {
                    this.getItems();
                    this.toastr.success(
                        this.TranslatePipe.transform('toast_removed_from_blocklist_msg'),
                        this.TranslatePipe.transform('toast_removed_from_blocklist_title')
                    );
                });
                break;
        }
    }

    getItems = (code?: string) => {
        this.SearchService.formatTags({ ...this.SEARCH }, this.getDefaultSearch()).then(
            (response) => {
                this.SEARCHED = response;
                this.loading = true;
                const SORT = { ...this.SORT };
                switch (SORT.code) {
                    case 'bounce_ts':
                        SORT.code = 'create_ts';
                        break;
                }
                const SEARCH = this.SearchService.getSearchBody(this.SEARCH, SORT, this.RPP, this.startRow);
                if (SEARCH?.environment === 'all') delete SEARCH.environment;
                this.subscriptions?.getItems?.unsubscribe();
                this.subscriptions.getItems = this.DefaultService.sendgridGetBounces(
                    SEARCH.orderBy,
                    SEARCH.startRow,
                    SEARCH.RPP,
                    SEARCH.term,
                    undefined,
                    SEARCH.message,
                    SEARCH.bounce_on_from,
                    SEARCH.bounce_on_until
                ).subscribe(
                    (next: any) => {
                        if (this.startRow && this.startRow >= next.rows) {
                            this.resetStartRow();
                            return;
                        }
                        this.items = this.getFormattedItems(next.data);
                        this.totalRows = next.rows;
                        this.ready = true;
                        this.loading = false;
                        if (code === 'changeStartRow') this.HelpersService.toTop();
                    },
                    (error) => {
                        // this.clickSort(this.getInitialSort().code, this.getInitialSearch().dir);
                        // setTimeout(() => {
                        //     this.search(true);
                        // }, 1);
                    }
                );
                // this.SEARCHED = response;
                // this.loading = true;
                // const SORT = { ...this.SORT };
                // const SEARCH = this.SearchService.getSearchBody(this.SEARCH, SORT, this.RPP, this.startRow);
                // if (SEARCH?.environment === 'all') delete SEARCH.environment;
                // let results = JSON.parse(JSON.stringify([...this.localItems]));
                // let slicedResults = [];
                // // sort
                // switch (SORT.code) {
                //     case 'bounce_ts':
                //         SORT.dir === 'asc'
                //             ? results.sort((a, b) => a.created - b.created)
                //             : results.sort((a, b) => b.created - a.created);
                //         break;
                // }
                // // filter
                // results = results.filter((item) => {
                //     const created: any = moment(moment.unix(item.created).format('YYYY-MM-DD'));
                //     // term
                //     if (SEARCH.term) {
                //         SEARCH.term = SEARCH.term.toString();
                //         SEARCH.term = SEARCH.term.toLowerCase();
                //         if (
                //             item.email.toLowerCase().indexOf(SEARCH.term) == -1 &&
                //             item.reason.toLowerCase().indexOf(SEARCH.term) == -1
                //         ) {
                //             return false;
                //         }
                //     }
                //     // message
                //     if (SEARCH.message) {
                //         SEARCH.message = SEARCH.message.toString();
                //         SEARCH.message = SEARCH.message.toLowerCase();
                //         if (item.reason.toLowerCase().indexOf(SEARCH.message) == -1) {
                //             return false;
                //         }
                //     }
                //     // bounce_on_from
                //     if (SEARCH.bounce_on_from) {
                //         if (!created.isValid()) {
                //             return false;
                //         }
                //         if (created.isBefore(moment(SEARCH.bounce_on_from))) {
                //             return false;
                //         }
                //     }
                //     // bounce_on_until
                //     if (SEARCH.bounce_on_until) {
                //         if (!created.isValid()) {
                //             return false;
                //         }
                //         if (created.isAfter(moment(SEARCH.bounce_on_until))) {
                //             return false;
                //         }
                //     }
                //     return true;
                // });
                // // slice
                // slicedResults = results.slice(this.startRow, this.startRow + this.RPP);
                // this.items = this.getFormattedItems(slicedResults);
                // this.totalRows = results.length;
                // this.ready = true;
                // this.loading = false;
                // if (code === 'changeStartRow') this.HelpersService.toTop();
            },
            (error) => {
                // this.clickSort(this.getInitialSort().code, this.getInitialSearch().dir);
                // setTimeout(() => {
                //     this.search(true);
                // }, 1);
            }
        );
    };

    getFormattedItems(items) {
        return items.map((item) => {
            return this.getFormattedItem(item);
        });
    }

    getFormattedItem(item) {
        return {
            ...item,
            to: {
                type: 'title',
                title: item.email
            },
            message: {
                type: 'default',
                value: item.reason,
                color: this.EventColorPipe.transform('bounce')
            },
            bounce_ts: {
                type: 'ts',
                ts: item.create_ts,
                format: 'prettyDateTime'
            },
            actions: {
                type: 'actions',
                actions: [
                    {
                        name: this.TranslatePipe.transform('blocklist_action_remove'),
                        code: 'remove',
                        icon: 'trash'
                    }
                ]
            }
        };
    }

    clearTypeaheads() {
        this.typeaheadModels = {
            project: null
        };
    }

    handleTypeaheads() {
        this.clearTypeaheads();
        if (this.SEARCH['project']) {
            this.typeaheadModels['project'] = {
                title:
                    this.AuthenticationService.getUserValue()['projects'].find((item) => {
                        return item.value === this.SEARCH['project'];
                    }).title || this.SEARCH['project'],
                value: this.SEARCH['project']
            };
        }
    }
    // END CUSTOM
}
