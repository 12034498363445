<ng-container *ngIf="!error && mail">
    <!-- head -->
    <div class="head-wrap">
        <!-- title sub -->
        <div class="title-wrap">
            <div class="title">{{mail.subject}}</div>
            <div class="sub">{{mail.from}} <span class="sep-dot"></span> {{mail.sent_ts | formatTs: 'prettyDateTime'}}</div>
        </div>
        <!-- buttons -->
        <div class="d-flex" style="min-height: 40px;">
            <div class="d-flex position-relative" (click)="helper.action === 'slideoutActions' ? helper.action = null : helper.action = 'slideoutActions'; $event.stopPropagation();" *ngIf="mail.blob_url">
                <button class="button tertiary ml-xxs"><i class="im-icon im-icon-actions icon-l m-0" style="font-size: 16px; transform: translateY(0);"></i></button>
                <app-popover [open]="helper.action === 'slideoutActions'" positionClass="left">
                    <ul (click)="helper.action = null; $event.stopPropagation();" appClickOutside (clickOutside)="helper.action = null">
                        <li class="position-relative d-flex align-items-center" (click)="resend()">
                            <i class="im-icon im-icon-send"></i> <span>{{'maildetail_action_resend' | translate}}</span>
                        </li>
                    </ul>
                </app-popover>
            </div>
        </div>
    </div>
    <!-- bounce reasons -->
    <div class="bounce-reasons" *ngIf="mail?.error_reasons?.length">
        <ng-container *ngFor="let reason of mail?.error_reasons">
            <div class="reason" [class.reason--warning]="reason.event === 'Deferred' || reason.event === 'deferred'"><i class="im-icon im-icon-warning"></i> {{reason.reason || reason.event}} <ng-container *ngIf="reason.email">({{reason.email}})</ng-container></div>
        </ng-container>
    </div>
    <!-- table -->
    <table class="table--detail table--detail--slideout">
        <tr>
            <th>{{'maildetail_th_to' | translate}}</th>
            <th></th>
            <th width="1%"></th>
        </tr>
        <ng-container *ngFor="let recipient of mail?.recipients; index as $i">
            <tr *ngIf="recipientLimit === false || ($i + 1) <= recipientLimit">
                <td>
                    <span class="has-stamps">{{recipient.address}}</span>
                    <div class="stamps">
                        <div class="stamp stamp--passive" *ngIf="recipient.type">{{recipient.type}}</div>
                    </div>
                </td>
                <td class="text-right" [ngStyle]="{'color': (recipient.event | eventColor)}">{{recipient.event | titlecase}}</td>
                <td>
                    <div class="action-button d-flex align-items-center actions position-relative" [class.active]="helper.action === ('slideoutAction_' + $i)" (click)="helper.action === ('slideoutAction_' + $i) ? helper.action = null : helper.action = ('slideoutAction_' + $i); $event.stopPropagation();" *ngIf="mail.blob_url">
                        <i class="im-icon im-icon-actions"></i>
                        <app-popover [open]="helper.action === ('slideoutAction_' + $i)" positionClass="left">
                            <ul (click)="helper.action = null; $event.stopPropagation();" appClickOutside (clickOutside)="helper.action = null">
                                <li class="position-relative d-flex align-items-center" (click)="resend(recipient.address)">
                                    <i class="im-icon im-icon-send"></i> <span>{{'maildetail_action_resend' | translate}}</span>
                                </li>
                            </ul>
                        </app-popover>
                    </div>
                </td>
            </tr>
        </ng-container>
    </table>
    <button *ngIf="recipientLimit !== false && recipientLimit < mail?.recipients?.length" (click)="recipientLimit = false" class="button-link mt-xxs">
        <span class="underline">{{'maildetail_show_more_1' | translate}} {{mail?.recipients?.length - recipientLimit}} {{((mail?.recipients?.length - recipientLimit) === 1 ? 'maildetail_show_more_2' : 'maildetail_show_more_2_plural') | translate}}</span>
    </button>
    <!-- mail -->
    <div class="mail-wrap" *ngIf="mailReady && mailObject">
        <iframe id="mailFrame" (load)="resizeIframe($event)" [attr.srcDoc]="mailObject.html | safe: 'html'" frameborder="0"></iframe>
    </div>
    <!-- loading || empty -->
    <div class="mail-wrap mail-wrap--loading" *ngIf="!mailReady || !mailObject">
        <app-spinner [after]="300" *ngIf="!mailReady" [centerVertically]="true"></app-spinner>
        <div class="error" *ngIf="mailReady && !mailObject">
            <img src="/assets/img/icons/mail-emptystate.svg" alt="no preview">
            <span class="title">{{'maildetail_emptystate' | translate}}</span>
        </div>
    </div>
    <!-- attachments -->
    <ng-container *ngIf="mailReady && mailObject?.attachments?.length">
        <ng-container *ngFor="let attachment of mailObject?.attachments; index as $i">
            <div class="attachment-wrap">
                <i class="im-icon im-icon-paperclip"></i> <span>{{attachment.filename}}</span> <span class="sep-dot"></span> <span class="passive">{{attachment?.blob?.size | fileSize}}</span> <button (click)="HelpersService.downloadBlob(attachment?.blob, attachment.filename)" class="button-link">{{'maildetail_download' | translate}}</button>
            </div>
        </ng-container>
    </ng-container>
    <!-- events -->
    <div class="events-wrap">
        <ng-container *ngIf="helper.showEvents">
            <table class="table--detail table--detail--slideout">
                <tr>
                    <th width="44%">{{'maildetail_th_to' | translate}}</th>
                    <th width="20%"></th>
                    <th width="35%"></th>
                    <th width="1%"></th>
                </tr>
                <ng-container *ngFor="let event of mail.events; index as $i">
                    <tr>
                        <td>{{event.email}}</td>
                        <td class="text-right" [ngStyle]="{'color': (event.event | eventColor)}">{{event.event | titlecase}}</td>
                        <td class="td-passive">{{event.sent_ts | formatTs: 'prettyDateTime'}}</td>
                        <td>
                            <div class="action-button d-flex align-items-center actions position-relative" [class.active]="helper.action === ('slideoutAction_' + $i)" (click)="helper.action === ('slideoutAction_' + $i) ? helper.action = null : helper.action = ('slideoutAction_' + $i); $event.stopPropagation();">
                                <i class="im-icon im-icon-actions"></i>
                                <app-popover [open]="helper.action === ('slideoutAction_' + $i)" positionClass="left">
                                    <ul (click)="helper.action = null; $event.stopPropagation();" appClickOutside (clickOutside)="helper.action = null">
                                        <li (click)="copyRaw(event)" class="position-relative d-flex align-items-center">
                                            <i class="im-icon im-icon-clipboard" style="font-size: 16px;"></i> <span>{{'maildetail_copy_raw_message' | translate}}</span>
                                        </li>
                                    </ul>
                                </app-popover>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
        <button (click)="helper.showEvents = !helper.showEvents" class="button-link mt-xxs" *ngIf="mail.events?.length">
            <span *ngIf="!helper.showEvents" class="underline">{{'maildetail_show_events_1' | translate}} {{mail.events.length}} {{(mail.events.length === 1 ? 'maildetail_show_events_2' : 'maildetail_show_events_2_plural') | translate}}</span>
            <span *ngIf="helper.showEvents" class="underline">{{'maildetail_hide_events' | translate}}</span>
        </button>
    </div>
</ng-container>

<!-- error -->
<ng-container *ngIf="error">
    <div class="error">
        <img src="/assets/img/icons/mail-emptystate.svg" alt="no preview">
        <span class="title">{{'maildetail_emptystate' | translate}}</span>
    </div>
</ng-container>