<div class="d-flex h-100 align-items-center">
    <div style="cursor: pointer;" class="p-0 ml-2 position-relative d-flex align-items-center" (click)="profileOpen = true;" appClickOutside (clickOutside)="profileOpen = null;">
        <app-avatar (click)="toggleProfileOpen(); $event.stopPropagation();" [class.hightlight-hover]="!profileOpen" style="transition: .2s ease;" [user]="user" size="40" fontSize="16"></app-avatar>
        <app-popover [open]="profileOpen" positionClass="left" style="transform: translatey(30px);">
            <div class="profile-popover d-flex flex-column">
                <div class="options">
                    <ul>
                        <li class="d-flex logout" (click)="AuthenticationService.logout()">
                            <a class="d-flex align-items-center">
                                <span><i class="im-icon im-icon-logout" style="transform: translateX(1px);"></i></span>
                                <div class="flex-grow-1">Log out</div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </app-popover>
    </div>
</div>