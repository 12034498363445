<div class="scroll-xx table-component table-component--padded" [class.loading-timed-out]="loadingTimedOut && ready">
    <!-- loadingTimedOut -->
    <ng-container *ngIf="loadingTimedOut && ready">
        <div class="loading-timed-out-spinner">
            <app-spinner></app-spinner>
        </div>
    </ng-container>
    <table class="table">
        <thead>
        <tr>
            <ng-container *ngFor="let head of heads">
                <th [ngStyle]="{'width' : head.width ? head.width : 'auto' }" class="" [class.saving]="loading || (!ready || notReady)" [class.sortable]="head.sortable" (click)="head.sortable && clickSort(head)"><div class="position-relative">{{head.name}}<i *ngIf="head.sortable" [ngClass]="getSortingClass(head)"></i></div></th>
            </ng-container>
        </tr>
        </thead>
        <tbody *ngIf="(ready && !notReady)">
            <ng-container *ngFor="let item of data; trackBy: identify;index as $i">
                <tr [class.active]="item[id] == selected" [class.clickable]="clickable">
                    <ng-container *ngFor="let head of heads;trackBy: identify;">
                        <td [class.td--mw-0]="item[head.code]?.mw0" [class.shrink]="item[head.code] && item[head.code].type === 'actions'" [class.linethrough]="item && item.linethrough && item[head.code] && head.code !== 'actions'" class="saving--pre {{(item[head.code]?.classList || '')}}" [class.saving]="loading">

                            <!-- link -->
                            <ng-container *ngIf="linkElement">
                                <a (click)="false && $event.preventDefault(); clickData.emit({item: item, head: head.code})" draggable="false" [routerLink]="['./']" queryParamsHandling="merge" [queryParams]="{id: item[id]}"  class="pad">
                                    <ng-container [ngTemplateOutlet]="tdData" [ngTemplateOutletContext]="{item: item, head: head, $i: $i}"></ng-container>
                                </a>
                            </ng-container>

                            <!-- no link -->
                            <ng-container *ngIf="!linkElement">
                                <div (click)="$event.preventDefault(); clickData.emit({item: item, head: head.code})" class="pad">
                                    <ng-container [ngTemplateOutlet]="tdData" [ngTemplateOutletContext]="{item: item, head: head, $i: $i}"></ng-container>
                                </div>
                            </ng-container>
                            
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
        <!-- shimmer -->
        <tbody *ngIf="(!ready || notReady)" class="shimmer-body">
            <ng-container *ngFor="let item of shimmerRows">
                <tr>
                    <td [attr.colspan]="heads.length"><app-shimmer></app-shimmer></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<!-- emptystate -->
<div *ngIf="!loading && data && !data.length && emptyState" class="mt-s">
    <app-empty-state [value]="emptyState"></app-empty-state>
</div>
<!-- pagination / export -->
<div *ngIf="(ready && !notReady) && data && data.length" class="row mt-l">
    <div class="col-md-4">
        <div class="d-flex justify-content-end pl-s">
            <!--  -->
        </div>
    </div>
    <div class="col-md-4">
        <div class="d-flex justify-content-center" *ngIf="totalRows > RPP" [class.saving]="loading">
            <app-pagination 
                [RPP]="RPP"
                [startRow]="startRow"
                [totalRows]="totalRows"
                (startRowChange)="startRowChange.emit($event)">
            </app-pagination>
        </div>
    </div>
    <div class="col-md-4 d-flex flex-row align-items-center">
        <div *ngIf="exportLabel" class="d-flex w-100 justify-content-end pr-s">
            <button (click)="clickExport.emit()" [class.disabled]="exportBusy" class="button-link fontsize-s export-btn">
                <i *ngIf="!exportBusy" class="im-icon im-icon-download"></i>
                <app-inline-spinner *ngIf="exportBusy" class="mr-1"></app-inline-spinner>
                <span>{{exportLabel}}</span>
            </button>
        </div>
    </div>
</div>


<!-- tData template -->
<ng-template #tdData let-item='item' let-head='head' let-$i="$i">
    <!-- default -->
    <ng-container *ngIf="!item[head.code] || !item[head.code].type">{{head.code === 'empty' ? '' : item[head.code] | display}}</ng-container>

    <!-- default type (with props) -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'default'"><span [class.data-ellipsis]="item[head.code].ellipsis" [ngStyle]="{'color': item[head.code].color ? item[head.code].color : ''}" [class.has-stamps]="item[head.code]?.stamps?.length" [class]="item[head.code].classList">{{item[head.code].value | display}}</span></ng-container>

    <!-- title -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'title'"><span [class.data-ellipsis]="item[head.code].ellipsis" [class.has-stamps]="item[head.code]?.stamps?.length" class="title">{{item[head.code].title}}</span></ng-container>

    <!-- titleSubtitle -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'titleSubtitle'"><div class="d-inline-flex flex-column title-subtitle"><span class="title">{{item[head.code].title}}</span><span class="subtitle" *ngIf="item[head.code].subtitle">{{item[head.code].subtitle}}</span></div></ng-container>

    <!-- ts -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'ts'"><span style="white-space: nowrap;">{{item[head.code].ts | formatTs: item[head.code].format : item[head.code].unix || false}}</span></ng-container>
    
    <!-- actions -->
    <ng-container *ngIf="item[head.code] && item[head.code].type === 'actions'">
        <div *ngIf="item[head.code].actions && item[head.code].actions.length" class="action-button d-flex align-items-center actions position-relative" class="action-button d-flex align-items-center actions position-relative" [class.active]="helper.action === $i" (click)="helper.action === $i ? helper.action = null : helper.action = $i; $event.stopPropagation(); $event.preventDefault()">
            <i class="im-icon im-icon-actions"></i>
            <app-popover [open]="helper.action === $i" positionClass="left">
                <ul (click)="helper.action = null; $event.stopPropagation();">
                    <li class="position-relative" *ngFor="let action of item[head.code].actions; trackBy: identify;" appClickOutside (clickOutside)="helper.action = null" (click)="$event.preventDefault(); action.confirmDelete ? $event.stopPropagation() || popoverHelper = 'delete_' + $i : clickAction.emit({item: item, action: action.code});" [ngClass]="action.class || ''">
                        <i *ngIf="action.icon" class="im-icon im-icon-{{action.icon}}"></i> 
                        {{action.name}}
                        <app-confirm-delete *ngIf="action.confirmDelete" (confirm)="clickAction.emit({item: item, action: action.code}); dismissPopover(); setActionIndex(null);" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_' + $i" positionClass="left"></app-confirm-delete>
                    </li>
                </ul>
            </app-popover>
        </div>
    </ng-container>

    <!-- stamps -->
    <ng-container *ngIf="item[head.code]?.stamps?.length">
        <div class="stamps">
            <span class="spacer"></span>
            <ng-container *ngFor="let stamp of item[head.code].stamps">
                    <span class="stamp" [class]="stamp.class">{{stamp.value}}</span>
            </ng-container>
        </div>
    </ng-container>
</ng-template>